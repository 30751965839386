import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classes from '../../assets/styles/WinnerList.module.css';
import NoData from '../../utils/NoData';
import Loader from '../../utils/Loader';
import { useNavigate } from 'react-router-dom';
import { getMethod } from '../../services';
import { TWO_D_WINNER_API } from '../../services/constant';
import Pagination from '../../utils/Pagination';
import Header from '../../layout/Header';
import Nav from '../../layout/Nav';
import { IoIosArrowRoundBack } from 'react-icons/io';
import TopLeaderboard from '../../utils/TopLeaderboard';
import Select from 'react-select';

const WkWinnerList = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [winnerLists, setWinnerLists] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [from, setFrom] = useState(1);
    const [page, setPage] = useState(1);
    const [time, setTime] = useState('9')

    const fetchWinnerList = async () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('b_m_a'));
        if (token) {
            const res = await getMethod(`${TWO_D_WINNER_API}?type=Weekend&time=${time}`, token.token);
            if (res) {
                setLoading(false);
                if (res.data) {
                    setWinnerLists(res.data);
                }
                if (res.meta) {
                    if (res.meta.from) {
                        setFrom(res.meta.from)
                    }
                    if (res.meta.last_page) {
                        setTotalPage(res.meta.last_page);
                    };
                };
            };
        } else {
            navigate("/login")
        }
    }
    useEffect(() => {
        fetchWinnerList();
    }, [page, time]);

    const backHandler = () => navigate(-1);

    const lotteryTimeLists = [
        { value: "9", label: "11:00 AM" },
        { value: "10", label: "01:00 PM" },
        { value: "11", label: "03:00 PM" },
        { value: "12", label: "05:00 PM" },
        { value: "13", label: "07:00 PM" },
        { value: "14", label: "09:00 PM" },
      ];
    return (
        <>
            <div className='pb-[100px] relative min-h-screen'>
                <Header />
                <div className='px-3 mt-3'>
                    <button className='bg-[var(--main-color)] p-1 rounded-full' onClick={backHandler}>
                    <IoIosArrowRoundBack size={'30px'}/>
                    </button>
                </div>
                <TopLeaderboard data={winnerLists}/>
                <div className="container mx-auto min-h-[70vh]">
                    <div className='p-3'>
                        <div className='flex items-center justify-center flex-col cursor-pointer'>
                            {/* <img src={prizeImg} className='w-[50px]' /> */}
                            <p className='text-[16px] mb-0 mt-3 text-black'>ထီပေါက်သူများ</p>
                        </div>
                        <div className={`shadow ${classes['auth-card-body']} bg-[var(--primary-color)] rounded-lg mt-2`}>
                            <div className='flex justify-end mb-2 relative z-50'>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={`${lotteryTimeLists[0].label || ""}`}
                                    name="color"
                                    options={lotteryTimeLists}
                                    onChange={(e) => setTime(e.value)}
                                />
                            </div>
                            <div className={classes['table-card-container']}>
                                <ul className={classes['table-header']}>
                                    <li><b>စဉ်</b></li>
                                    <li>အမည်</li>
                                    <li>ဖုန်းနံပါတ်</li>
                                    <li>ထိုးငွေ</li>
                                    <li>စုစုပေါင်း</li>
                                    <li>အချိန်</li>
                                    <li>ရက်စွဲ</li>
                                </ul>
                                {
                                    winnerLists.length > 0 ?
                                        winnerLists.map((winner, i) =>
                                            <ul key={i} className={classes['table-header']}>
                                                <li>{i + from}.</li>
                                                <li className='text-[var(--main-color)]'>{winner.name}</li>
                                                <li>{'****'+ winner.phone?.slice(-4)}</li>
                                                <li>{winner.amount.toLocaleString('en-us')}</li>
                                                <li>{winner.total.toLocaleString('en-us')}</li>
                                                <li>{winner.lottery_time === 'Seven' ? '12:01' : '04:30'} PM</li>
                                                <li>{winner.date}</li>
                                            </ul>
                                        ) :
                                        <NoData />
                                }
                            </div>
                            {
                                totalPage > 1 &&
                                <div className='flex justify-center pt-2'>
                                    <Pagination setPage={setPage} totalPage={totalPage} setLoading={setLoading} />
                                </div>
                            }
                        </div>
                        {
                            ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
                        }
                    </div>
                </div>
                <Nav />
            </div>
        </>
    )
}

export default WkWinnerList
