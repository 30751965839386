import React, { useEffect } from "react";
import { FiCheck, FiLoader, FiX } from "react-icons/fi";
import { langChange } from "../lang";
import { useSelector } from "react-redux";

export default function TransactionCard({ type = "deposit", data }) {
  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  return (
    <>
      <div className="shadow bg-[var(--primary-color)] rounded-md p-3 mb-2 animate-fadein">
        <div className="flex justify-center mb-2"> 
          {(data?.status === 'Pending' || data?.status === 'Uncomplete') && <FiLoader className="w-[40px] h-[40px] text-yellow-500 rotate-element" />}
          {data?.status === 'Rejected' && <FiX className="border w-[40px] h-[40px] p-2 rounded-full text-red-600 border-red-700" />}
          {(data?.status === 'Approved' || data?.status === 'Completed') && <FiCheck className="border w-[40px] h-[40px] p-2 rounded-full text-green-600 border-green-700" />}
        </div>
        { (data?.status === 'Pending' || data?.status === 'Uncomplete') && <p className="text-center text-normal text-yellow-500">{langChange.pending}</p> }
        { data?.status === 'Rejected' && <p className="text-center text-normal text-red-500">{langChange.rejected}</p> }
        { (data?.status === 'Approved' || data?.status === 'Completed') && <p className="text-center text-normal text-green-500">{langChange.approved}</p> }
        <p className="text-center py-1 text-xl text-slate-800">{type === 'deposit' ? '+' : '-'} {data?.amount?.toLocaleString('en-us')} {langChange.kyat}</p>
        <div
          className="h-[1px] my-2"
          style={{
            background:
              "repeating-linear-gradient(to right, transparent,transparent 10px,gray 10px,gray 20px)",
          }}
        ></div>
        <div className="flex justify-between items-center mt-1">
          <p className="text-slate-900 text-[14px]">
            {type === "deposit"
              ? langChange.deposit_trans_time
              : langChange.withdraw_trans_time}
          </p>
          <p className="text-slate-900 text-[14px]">{data?.created_at}</p>
        </div>
        {type === "deposit" ? (
          <div className="flex justify-between items-center mt-1">
            <p className="text-slate-900 text-[14px]">
              {langChange.transaction_no}
            </p>
            <p className="text-slate-900 text-[14px]">{data?.transation_no}</p>
          </div>
        ) : (
          ""
        )}
        <div className="flex justify-between items-center mt-1">
          <p className="text-slate-900 text-[14px]">{langChange.remark}</p>
          <p className="text-slate-900 text-[14px]">{data?.remark ?? '- - -'}</p>
        </div>
      </div>
    </>
  );
}
