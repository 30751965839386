import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo/logo.png";
import classes from "../assets/styles/Header.module.css";
import profileImage from "../assets/images/vip.png";
import { USER_INFO_API } from "../services/constant";
import { getMethod } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { infoHandler } from "../store/slices/userInfoSlice";
import { langChange } from "../lang";
import Spinner from "../utils/Spinner";
import { FiDownload } from "react-icons/fi";


let deferredPrompt;

window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault();
  deferredPrompt = event;
  deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === 'accepted') {
      document.querySelector(".pwa-button")?.classList.toggle('flex')
      document.querySelector(".pwa-button")?.classList.toggle('hidden')
    }
  });
  document.querySelector(".pwa-button")?.classList.toggle('flex')
  document.querySelector(".pwa-button")?.classList.toggle('hidden')
});

const Header = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { value } = useSelector(state => state.userInfoSlice);

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const fetchUserInfo = async () => {
    const token = localStorage.getItem("b_m_a");
    if (token) {
      const deToken = JSON.parse(token);
      let res = await getMethod(
        USER_INFO_API + "?game_token=" + deToken.game_token,
        deToken.token
      );
      if (res) {
        if (res.message === "Unauthenticated.") {
          localStorage.removeItem("b_m_a");
          navigate("/login");
        }
        if (res.con) {
          setData(res.data?.user_info);
          dispatch(infoHandler(res?.data));
        }
      }
    }
  };

  useEffect(() => {
    !value?.game_info && fetchUserInfo()
  }, [value]);
  const triggerPWAInstallation = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    };
  };

  return (
    <div className={`bg-[var(--main-color)] sticky top-0 z-50 rounded-b-lg`}>
      <nav className="container mx-auto bg-transparent flex justify-between items-center px-4 py-2">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="logo" className="w-[50px]" />
          </Link>
          {/* <div className="hidden lg:block ml-20">
            <ul className="flex justify-between items-center select-none">
              <li className={`relative ${classes["sidebar-icon"]}`}>
                <Link to="/">
                  <span
                    className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                  >
                    {langChange.home}
                  </span>
                </Link>
              </li>
              <li className={`relative ${classes["sidebar-icon"]}`}>
                <Link to="/promotions">
                  <span
                    className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                  >
                    {langChange.promotion}
                  </span>
                </Link>
              </li>

              {localStorage.getItem("b_m_a") && (
                <>
                  <li className={`relative ${classes["sidebar-icon"]}`}>
                    <Link to="/notifications">
                      <span
                        className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                      >
                        {langChange.notification}
                      </span>
                    </Link>
                  </li>
                  <li className={`relative ${classes["sidebar-icon"]}`}>
                    <Link to="/pocket">
                      <span
                        className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                      >
                        {langChange.pocket}
                      </span>
                    </Link>
                  </li>
                  <li className={`relative ${classes["sidebar-icon"]}`}>
                    <Link to="/profile">
                      <span
                        className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                      >
                        {langChange.profile}
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div> */}
        </div>
        <div className="flex items-center">
          <div className="bg-yellow-400 mr-2 rounded-md py-1 px-3 flex justify-end items-center gap-2 hover:bg-yellow-500 cursor-pointer max-h-[35px] animate-fadein text-blue-900" onClick={() => triggerPWAInstallation()}>App <FiDownload /></div>
          {localStorage.getItem("b_m_a") ? (
            <div>
              <Link to="/profile">
                <div className={`${classes["profile"]}`}>
                  <img src={ value?.user_info?.photo ?? profileImage} className={`${classes["profile-img"]}`} />
                  <div>
                    <p className={classes["user-name"]}>{
                      value?.user_info?.name?.slice(0, 14) ?? <Spinner />
                    }{value?.user_info?.name?.slice?.length > 14 ? '...' : ''}</p>
                    <p className={classes["user-balance"]}>
                      {value?.user_info?.current_balance?.toLocaleString("en-us") ?? <Spinner />}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <ul className="flex justify-between items-center select-none py-2">
              {/* <li className={`relative ${classes["sidebar-icon"]}`}>
                <Link to="/login">
                  <span
                    className={`px-3.5 py-2 block text-[var(--accent-color)] whitespace-nowrap ${classes["hover-underline"]}`}
                  >
                    {langChange.login_first}
                  </span>
                </Link>
              </li> */}
              <li className={`${classes["sidebar-icon"]} ${classes.authActive}`}>
                <Link to="/login">
                  <span
                    className={`px-3.5 py-2 block text-[var(--main-color)] whitespace-nowrap`}
                  >
                    {langChange.login}
                  </span>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
