import React from 'react';
import classes from '../assets/styles/PopupFrame.module.css'
import { motion } from 'framer-motion';

function PopupFrame({ url, setUrl }) {


    return (
        <div className={url ? classes.popup :''}>
            <motion.div
                animate={{ scale: url ? 1 : 0 }}
                transition={{ duration: .5 }}
                className={classes.popup_motion}
            >
               {
                url &&
                <div className={classes.popup_content}>
                <span className={classes.close} onClick={() => setUrl('')}>
                    &times;
                </span>
                <iframe width={440} height='300' src={url} title={url} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
               }
            </motion.div>
        </div>
    );
}

export default PopupFrame;
