import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "../assets/styles/BetHistory.module.css";
import NoData from "../utils/NoData";
import Loader from "../utils/Loader";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../services";
import { TWO_D_BET_LIST_API } from "../services/constant";
import Pagination from "../utils/Pagination";
import { useSelector } from "react-redux";
import { langChange } from "../lang";
import BurmaTwodBetHistoryCard from "./BurmaTwodBetHistoryCard";
import Select from 'react-select';
import moment from "moment-timezone";

const BurmaTwodHistory = () => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [time, setTime] = useState({value: "One", label: langChange.morning});
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const groupDateByDate = (groupData) => {
    const groupedData = {};
    groupData.map((item) => {
      const date = item.date_time;
      if (!groupedData[date]) {
        groupedData[date] = [];
      }
      groupedData[date].push(item);
    });
    return groupedData;
  };

  const fetchHistory = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("b_m_a"));
    if (token) {
      const currentDate = moment().format('YYYY-MM-DD')
      const res = await getMethod(
        `${TWO_D_BET_LIST_API}?type=Burma&page=${page}&date=${currentDate}&time=${time}&limit=500`,
        token.token
      );
      if (res) {
        setLoading(false);
        if (res.data) {
          setHistory(groupDateByDate(res.data));
        }
        if (res.meta) {
          if (res.meta.last_page) {
            setTotalPage(res.meta.last_page);
          }
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchHistory();
  }, [page, time]);

  return (
    <>
      <div
        className={`${classes["auth-card-body"]} bg-[var(--primary-color)] rounded-lg my-3`}
      >
        <div className="flex justify-end mb-3">
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={time}
          name="color"
          options={
            [
              {value: "One", label: langChange.morning},
              {value: "Two", label: langChange.evening}
            ]
          }
          onChange={(e) => setTime(e.value)}
        />
        </div>
        <div className={classes["table-card-container"]}>
          {Object.keys(history).length > 0 ? (
            Object.keys(history).map((group_date) => (
              <BurmaTwodBetHistoryCard
                key={group_date}
                data={history[group_date]}
                date={group_date}
              />
            ))
          ) : (
            <NoData />
          )}
        </div>
        {totalPage > 1 && (
          <div className="flex justify-center pt-2">
            <Pagination
              setPage={setPage}
              totalPage={totalPage}
              setLoading={setLoading}
            />
          </div>
        )}
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default BurmaTwodHistory;
