export const getWeekendLotteryType = (time) => {
    let type;
    switch (time) {
      case "11:00 AM":
        type = "9";
        break;
      case "01:00 PM":
        type = "10";
        break;
      case "03:00 PM":
        type = "11";
        break;
      case "05:00 PM":
        type = "12";
        break;
      case "07:00 PM":
        type = "13";
        break;
      case "09:00 PM":
        type = "14";
        break;
    }
    return type;
  };
  
  export const getWeekendLotteryTime = (type) => {
    let time;
    switch (type + '') {
      case "9":
        time = "11:00 AM";
        break;
      case "10":
        time = "01:00 PM";
        break;
      case "11":
        time = "03:00 PM";
        break;
      case "12":
        time = "05:00 PM";
        break;
      case "13":
        time = "07:00 PM";
        break;
      case "14":
        time = "09:00 PM";
        break;
    }
    return time;
  };
  