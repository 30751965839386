import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import classes from "../assets/styles/RegisterVerify.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/images/logo/logo.png";
import Card from "../utils/Card";
import { userDataHandler } from "../store/slices/registerSlice";
import { langChange } from "../lang";

const ForgotVerify = () => {
  const [otp, setOtp] = useState("");
  const { registerVal } = useSelector((state) => state.registerSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  useEffect(() => {
    if (!registerVal.phone) {
      navigate("/forgot-password");
    }
  }, [registerVal]);

  const submitHandler = async () => {
    const data = {
      ...registerVal,
      token: otp,
    };
    dispatch(userDataHandler(data));
    navigate("/verify/password-create");
  };

  useEffect(() => {
    if (otp.length === 6) {
      submitHandler();
    }
  }, [otp]);

  return (
    <>
      <div className="mx-auto h-screen py-6">
        <div>
          <div className="w-40 mx-auto mb-3">
            <Link to="/">
              <img src={logo} className="w-full" />
            </Link>
          </div>
        </div>
        <div className="p-4">
          <div className={classes["auth-card-header"]}>
            {langChange.enter_otp_from_sms}
          </div>
          <form>
            <div className="flex items-center justify-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                renderInput={(props) => (
                  <input {...props} className={classes["opt-input"]} />
                )}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotVerify;
