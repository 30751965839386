import React from 'react'
import Wk2DLive from './Wk2DLive'

export default function WkTwodResultHistory() {
  return (
    <div className='mt-3'>
      <Wk2DLive route='result-history-iframe' />
    </div>
  )
}
