// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Deposit_auth-card-header__jsoXp {
  display: flex;
  align-items: center;
}
.Deposit_auth-card-header__jsoXp span {
  text-transform: uppercase;
  display: block;
  letter-spacing: 1px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border-bottom: 2px solid transparent;
  padding: 10px;
  font-size: 16px;
}
.Deposit_auth-card-header__jsoXp span.Deposit_active__jk1X4 {
  /* border-bottom-color: #16a34a; */
  border-bottom-color: var(--main-color);
  color: var(--main-color);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Deposit.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,oCAAoC;EACpC,aAAa;EACb,eAAe;AACjB;AACA;EACE,kCAAkC;EAClC,sCAAsC;EACtC,wBAAwB;AAC1B","sourcesContent":[".auth-card-header {\n  display: flex;\n  align-items: center;\n}\n.auth-card-header span {\n  text-transform: uppercase;\n  display: block;\n  letter-spacing: 1px;\n  cursor: pointer;\n  user-select: none;\n  border-bottom: 2px solid transparent;\n  padding: 10px;\n  font-size: 16px;\n}\n.auth-card-header span.active {\n  /* border-bottom-color: #16a34a; */\n  border-bottom-color: var(--main-color);\n  color: var(--main-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth-card-header": `Deposit_auth-card-header__jsoXp`,
	"active": `Deposit_active__jk1X4`
};
export default ___CSS_LOADER_EXPORT___;
