import React, { useEffect, useState } from 'react';
import classes from '../assets/styles/TopLeaderboard.module.css';
import profileImage from "../assets/images/vip.png";

const TopLeaderboard = ({data}) => {
  const [topThree,setTopThree] = useState([]);
  useEffect(()=>{
    const sortDesc = data.sort((a, b) => b.total - a.total);
    const topThree = sortDesc.slice(0, 3);
    setTopThree(topThree);
  },[data])
  return (
    <>
        <div className={classes['podium-container']}>
            <div className={classes.podium}>
                <div className={`${classes.podium__front} ${classes.podium__left}`}>
                    <div className="">2</div>
                    <div className={classes.podium__image}>
                        <div className={classes.card}>
                            <img src={profileImage} />
                            <p>{topThree[1]?.name ?? '??'}</p>
                            <p>
                                {
                                    topThree[1]?.phone ?
                                    '****'+ topThree[1]?.phone?.slice(-4):
                                    '****??'

                                }
                            </p>
                            <hr/>
                            <p>ထိုးငွေ &nbsp; {topThree[1]?.amount?.toLocaleString('en-us') ?? '??'}</p>
                            <hr />
                            <p>အနိုင်ရငွေ &nbsp;{topThree[1]?.total?.toLocaleString('en-us') ?? '??'}</p>
                        </div>
                    </div>
                </div>
                <div className={`${classes.podium__front} ${classes.podium__center}`}>
                    <div className="">1</div>
                    <div className={classes.podium__image}>
                        <div className={classes.card}>
                            <img src={profileImage} />
                            <p>{topThree[0]?.name ?? '??'}</p>
                            <p>
                                {
                                    topThree[0]?.phone ?
                                    '****'+ topThree[0]?.phone?.slice(-4):
                                    '****??'

                                }
                            </p>
                            <hr/>
                            <p>ထိုးငွေ &nbsp; {topThree[0]?.amount?.toLocaleString('en-us') ?? '??'}</p>
                            <hr />
                            <p>အနိုင်ရငွေ &nbsp;{topThree[0]?.total?.toLocaleString('en-us') ?? '??'}</p>
                        </div>
                    </div>
                </div>
                <div className={`${classes.podium__front} ${classes.podium__right}`}>
                    <div class="">3</div>
                    <div className={classes.podium__image}>
                        <div className={classes.card}>
                            <img src={profileImage} />
                            <p>{topThree[2]?.name ?? '??'}</p>
                            <p>
                                {
                                    topThree[2]?.phone ?
                                    '****'+ topThree[2]?.phone?.slice(-4):
                                    '****??'

                                }
                            </p>
                            <hr/>
                            <p>ထိုးငွေ &nbsp; {topThree[2]?.amount?.toLocaleString('en-us') ?? '??'}</p>
                            <hr />
                            <p>အနိုင်ရငွေ &nbsp;{topThree[2]?.total?.toLocaleString('en-us') ?? '??'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default TopLeaderboard
