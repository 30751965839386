import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";

export default function ContactCard({ title, link, redLink, icon, iconColor }) {

  return (
    <a href={redLink} target="_blank" className="shadow mb-1 bg-[var(--primary-color)] w-full p-5 rounded-lg overflow-hidden gap-3 animate-fadein">
      <div className="flex justify-between items-center mb-3">
        <div className={`min-w-[45px] min-h-[45px] rounded-full ${iconColor} flex justify-center items-center text-[#FFF] cursor-pointer`}>
          {icon}
        </div>
        <div className="w-full overflow-hidden ml-4">
          <p className="whitespace-nowrap text-black mb-1">{title} <span className="font-extralight">ဖြင့်ဆက်သွယ်ပေးပါ</span></p>
          <p className="whitespace-nowrap text-black">Our Service is 24/7</p>
        </div>
      </div>
      <div className="w-full overflow-hidden">
          <p className="font-extralight text-black mb-10">ဝန်ဆောင်မှု {title === "Facebook"?"Page": "ဖုန်း"}</p>
          <p className="font-extralight text-black flex items-center justify-between">
            {link}
            <FaPhoneAlt color="green" size="17px" />
          </p>
      </div>
    </a>
  );
}
