import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../layout/Header';
import classes from '../assets/styles/Deposit.module.css';
import WithdrawForm from './WithdrawForm';
import WithdrawHistory from './WithdrawHistory';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
import Nav from '../layout/Nav';
import { IoIosArrowRoundBack } from 'react-icons/io';

const Withdraw = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang])
  const backHandler = () => navigate(-1);
  return (
    <>
      <Header />
      <div className='px-3 mt-3'>
          <button className='bg-[var(--main-color)] p-1 rounded-full' onClick={backHandler}>
            <IoIosArrowRoundBack size={'30px'}/>
          </button>
      </div>
      <div className='mx-auto h-screen'>
        <div className='p-3 pt-0'>
            <div className={classes['auth-card-header']}>
                 <span className={`text-black ${url === '/withdraw' && classes.active}`} onClick={()=> navigate('/withdraw')}>{langChange.withdraw}</span>
                 <span className={`text-black ${url === '/withdraw-history' && classes.active}`} onClick={()=> navigate('/withdraw-history')}>{langChange.history}</span>
            </div>
            {
              url === '/withdraw'?
              <WithdrawForm /> :
              <WithdrawHistory />
            } 
        </div>
        <Nav />
      </div>
    </>
  )
}

export default Withdraw
