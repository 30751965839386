import React from 'react';
import Header from '../layout/Header';
import Contact from './Contact';
import Nav from '../layout/Nav';

const ContactPage = () => {
  return (
    <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className='p-4'>
          <Contact /> 
        </div>
        <Nav />
    </div>
  )
}

export default ContactPage
