import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imageLoader from '../assets/images/logo/logo.png';
import btiSportImg from '../assets/images/wbet.png';
import pragmaticImg from '../assets/images/pragmatic.png';
import live22Img from '../assets/images/live22.png';
import casinoImg from '../assets/images/casino.png';
import kingMakerImg from '../assets/images/kingmaker.png';
import classes from '../assets/styles/ProviderCard.module.css';
const ProviderCard = ({name,image,event}) => {
  const [imageLoad, setImageLoad] = useState(true);
  return (
    <li className='w-1/3 cursor-pointer select-none p-1 animate-fadein' onClick={event}>
        <div className='pb-1 rounded-lg'>
            <LazyLoadImage
                className={`backdrop-blur-md`}
                alt={name}
                src={imageLoad ? imageLoader : 
                   (name === 'Joker' && casinoImg) || 
                   (name === 'Pragmatic Play' && pragmaticImg) || 
                   (name === 'BTI Sportsbook' && btiSportImg) || 
                   (name === 'Jili' && live22Img) ||
                   (name === 'KING MAKER' && kingMakerImg) ||
                   image
                }
                afterLoad={() => setImageLoad(false)}
                beforeLoad={() => setImageLoad(true)}
                style={{background: 'hsla(0,0%,100%, 20%)'}}
                />
            <p className={`mb-0 mt-1.5 select-none text-center text-[var(--accent-color)] text-sm lg:text-base font-semibold ${classes.mobileFont}`}>{name === "NetEnt+RedTiger"? "REDTIGER": name?.toUpperCase()}</p>
        </div>
    </li>
  )
}

export default ProviderCard
