import React, { useEffect } from 'react';
import classes from '../assets/styles/NoData.module.css';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';

const NoData = () => {
  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang])
  return (
    <div className={classes['nofount']}>
         {langChange.no_data_found}
    </div>
  )
}

export default NoData