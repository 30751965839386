import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "../utils/Button";
import { Link, useNavigate } from "react-router-dom";
import { postMethod } from "../services";
import Input from "../utils/Input";
import logo from "../assets/images/logo/logo.png";
import { CHANGE_PASSWORD_API } from "../services/constant";
import SweetAlert from "sweetalert2";
import Card from "../utils/Card";
import { FiKey } from "react-icons/fi";
import Loader from "../utils/Loader";
import { langChange } from "../lang";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const oldPasswordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setOldPassword(e.target.value);
    setErrorOldPassword("");
  };

  const newPasswordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setNewPassword(e.target.value);
    setErrorNewPassword("");
  };

  const confirmPasswordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword("");
  };

  const changePasswordHandler = async (e) => {
    e.preventDefault();
    if (!oldPassword) {
      setErrorOldPassword("Old password is required.");
      return;
    }
    if (oldPassword.length !== 6) {
      setErrorOldPassword("Old password must be 6 digits.");
      return;
    }
    if (!newPassword) {
      setErrorNewPassword("New password is required.");
      return;
    }
    if (newPassword.length !== 6) {
      setErrorOldPassword("New password must be 6 digits.");
      return;
    }
    if (!confirmPassword) {
      setErrorConfirmPassword("Confirm password is required.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorConfirmPassword("Password does not match.");
      return;
    }
    setLoading(true);
    const token = localStorage.getItem("b_m_a");
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    if (token) {
      const deToken = JSON.parse(token);
      const res = await postMethod(CHANGE_PASSWORD_API, data, deToken.token);
      if (res) {
        setLoading(false);
        if (res === "Failed to fetch") {
          SweetAlert.fire({
            width: "330px",
            icon: "error",
            title: "Connection!",
            text: "Please check internet connection!",
            confirmButtonText: "Ok",
          });
        }
        if (res.con) {
          SweetAlert.fire({
            width: "330px",
            icon: "success",
            title: "Success!",
            text: "Password change successful!",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("b_m_a");
              navigate("/login");
            }
          });
        }
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <div className="mx-auto py-6 h-screen">
        <div>
          <div className="w-40 mx-auto mb-3">
            <Link to="/">
              <img src={logo} className="w-full" />
            </Link>
          </div>
          <div className="p-4">
            <form onSubmit={changePasswordHandler}>
              <Input
                icon={<FiKey />}
                label={langChange.old_password}
                type="password"
                value={oldPassword}
                event={oldPasswordHandler}
                error={errorOldPassword}
                placeholder={langChange.enter_old_password}
              />
              <Input
                icon={<FiKey />}
                label={langChange.new_password}
                type="password"
                value={newPassword}
                event={newPasswordHandler}
                error={errorNewPassword}
                placeholder={langChange.enter_new_password}
              />
              <Input
                icon={<FiKey />}
                label={langChange.confirm_password}
                type="password"
                value={confirmPassword}
                event={confirmPasswordHandler}
                error={errorConfirmPassword}
                placeholder={langChange.enter_confirm_password}
              />
              <div className="text-center">
                <Button
                  label={langChange.confirm}
                  type="submit"
                  disable={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default ChangePassword;
