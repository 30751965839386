// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwodHkwayHtoe_auth-card-body__NZ-Iu {
  padding: 20px;
}
.TwodHkwayHtoe_table-card-container__luXzc {
  overflow: auto;
  font-size: 13px;
  max-height: 500px;
  width: 100%;
  border-radius: 7.5px;
}
.TwodHkwayHtoe_table-card-container__luXzc ul:first-child {
  position: sticky;
  top: 0;
  z-index: 99;
}
.TwodHkwayHtoe_table-card-container__luXzc ul:first-child li {
  background-color: var(--primary-color);
  color: #000;
  border-top: 0.5px solid #ddd;
  border-bottom: 0.5px solid #ddd;
}
.TwodHkwayHtoe_table-header__RZ71D {
  display: flex;
  margin-bottom: 10px;;
}
.TwodHkwayHtoe_table-header__RZ71D li:first-child {
  flex: 0 0 30%;
  border-radius: 10px 0 0 10px;
  text-align: center;
}
.TwodHkwayHtoe_table-header__RZ71D li:nth-child(2) {
  flex: 0 0 35%;
  text-align: center;
}
.TwodHkwayHtoe_table-header__RZ71D li:nth-child(3) {
  flex: 0 0 35%;
  border-radius: 0 10px 10px 0;
  text-align: center;
}
.TwodHkwayHtoe_table-header__RZ71D li {
  background-color: var(--primary-color);
  padding: 15px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .TwodHkwayHtoe_table-card-container__luXzc {
    max-height: 450px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .TwodHkwayHtoe_table-card-container__luXzc {
    max-height: 400px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/TwodHkwayHtoe.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,gBAAgB;EAChB,MAAM;EACN,WAAW;AACb;AACA;EACE,sCAAsC;EACtC,WAAW;EACX,4BAA4B;EAC5B,+BAA+B;AACjC;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,4BAA4B;EAC5B,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,4BAA4B;EAC5B,kBAAkB;AACpB;AACA;EACE,sCAAsC;EACtC,aAAa;AACf;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".auth-card-body {\n  padding: 20px;\n}\n.table-card-container {\n  overflow: auto;\n  font-size: 13px;\n  max-height: 500px;\n  width: 100%;\n  border-radius: 7.5px;\n}\n.table-card-container ul:first-child {\n  position: sticky;\n  top: 0;\n  z-index: 99;\n}\n.table-card-container ul:first-child li {\n  background-color: var(--primary-color);\n  color: #000;\n  border-top: 0.5px solid #ddd;\n  border-bottom: 0.5px solid #ddd;\n}\n.table-header {\n  display: flex;\n  margin-bottom: 10px;;\n}\n.table-header li:first-child {\n  flex: 0 0 30%;\n  border-radius: 10px 0 0 10px;\n  text-align: center;\n}\n.table-header li:nth-child(2) {\n  flex: 0 0 35%;\n  text-align: center;\n}\n.table-header li:nth-child(3) {\n  flex: 0 0 35%;\n  border-radius: 0 10px 10px 0;\n  text-align: center;\n}\n.table-header li {\n  background-color: var(--primary-color);\n  padding: 15px;\n}\n\n@media (min-width: 481px) and (max-width: 767px) {\n  .table-card-container {\n    max-height: 450px;\n  }\n}\n\n@media (min-width: 320px) and (max-width: 480px) {\n  .table-card-container {\n    max-height: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth-card-body": `TwodHkwayHtoe_auth-card-body__NZ-Iu`,
	"table-card-container": `TwodHkwayHtoe_table-card-container__luXzc`,
	"table-header": `TwodHkwayHtoe_table-header__RZ71D`
};
export default ___CSS_LOADER_EXPORT___;
