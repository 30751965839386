import React, { useEffect, useState } from 'react'
import classes from '../assets/styles/TwodResultHistory.module.css'
import { useNavigate } from 'react-router-dom';
import { TWO_D_RESULT_API } from '../services/constant';
import { getMethod } from '../services';
import Loader from '../utils/Loader';

const BurmaTwodResultHistory = () => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();

  const fetchHistory = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("b_m_a"));
    if (token) {
      //   const currentDate = format(new Date(), "yyyy-MM-dd");
      const res = await getMethod(`${TWO_D_RESULT_API}?type=Burma`, token.token);
      if (res) {
        setLoading(false);
        if (res.data) {
          setHistory(res.data);
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className="mt-6">
      {history.length > 0 && (
        <div className="mb-5">
          <h2 className="text-center text-[var(--main-color)] text-[100px] font-bold">
            {history.sort((a, b) => b.id - a.id)[0].two_digit_number ?? "!!"}
          </h2>
          <p className="text-center translate-y-[-10px] text-[var(--main-color)]">
            {history.sort((a, b) => b.id - a.id)[0].lottery_date ?? "--:--:--"}
            {history.sort((a, b) => b.id - a.id)[0].lottery_time === "One"
              ? `  ( 12:01 PM )`
              : `  ( 04:30 PM )`}
          </p>
        </div>
      )}
      {history.length > 0 ? (
        history.map((el) => (
          <div
            key={el.id}
            className="bg-[var(--primary-color)] p-6 rounded-lg text-base mb-3"
          >
            <div className={`text-center ${classes.result}`}>
              <div style={{ width: "33.33%" }}>{el.lottery_date}</div>
              <div
                style={{ width: "33.33%" }}
                className="text-[50px] text-[var(--main-color)]"
              >
                {el.two_digit_number}
              </div>
              <div style={{ width: "33.33%" }}>
                <span
                  className={`p-1 rounded shadow-lg px-3 text-xs text-white ${
                    el.lottery_time === "One" ? "bg-green-600" : "bg-red-500"
                  }`}
                >
                  {el.lottery_time === "One" ? "12:01 PM" : "04:30 PM"}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-xl h-24 flex items-end text-black justify-center">
          No Result Found!
        </div>
      )}
      {loading && <Loader />}
    </div>
  )
}

export default BurmaTwodResultHistory
