import React, { useState } from "react";
import ReactDOM from "react-dom";
import Input from "../utils/Input";
import Button from "../utils/Button";
import { FiKey, FiPhone } from "react-icons/fi";
import logo from "../assets/images/logo/logo.png";
import { Link, useNavigate } from "react-router-dom";
import classes from "../assets/styles/Login.module.css";
import { postMethod } from "../services";
import { GAME_REGISTER_API, LOGIN_API } from "../services/constant";
import SweetAlert from "sweetalert2";
import Loader from "../utils/Loader";
import { langChange } from "../lang";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const navigate = useNavigate();

  const phoneHandler = (e) => {
    setPhone(e.target.value);
    setErrorPhone("");
  };
  const passwordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setPassword(e.target.value);
    setErrorPassword("");
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    if (!phone) {
      setErrorPhone("Phone is required.");
      return;
    }
    if (!password) {
      setErrorPassword("Password is required.");
      return;
    }
    setLoading(true);
    const data = {
      phone,
      password,
    };
    const res = await postMethod(LOGIN_API, data);
    res && setLoading(false);
    if (res === "Failed to fetch") {
      SweetAlert.fire({
        width: "330px",
        icon: "error",
        title: "Connection!",
        text: "Please check internet connection!",
        confirmButtonText: "Ok",
      });
    }
    if (res.con) {
      if (res?.data?.game_token) {
        SweetAlert.fire({
          width: "330px",
          icon: "success",
          title: "Success!",
          text: res?.message,
          confirmButtonText: "Welcome",
        }).then(() => {
          const auth = {
            game_token: res.data.game_token,
            token: res.data.token,
            user_role: res.data.game_info?.role,
            user_id: res.data.user_info.id,
          };
          localStorage.setItem("b_m_a", JSON.stringify(auth));
          navigate("/");
        });
      } else {
        setLoading(true);
        postMethod(GAME_REGISTER_API, {}, res?.data?.token).then((gameRes) => {
          setLoading(false);
          if (gameRes?.con) {
            SweetAlert.fire({
              width: "330px",
              icon: "success",
              title: "Success!",
              text: gameRes?.message,
              confirmButtonText: "Welcome",
            }).then(() => {
              const auth = {
                ...res,
                ...gameRes,
              };
              localStorage.setItem("b_m_a", JSON.stringify(auth));
              navigate("/");
            });
          } else {
            SweetAlert.fire({
              width: "330px",
              icon: "error",
              title: "Sorry!",
              text: gameRes?.data?.error || gameRes?.message,
              confirmButtonText: "Ok",
            });
          }
        }).catch((err) => {
          setLoading(false);
          SweetAlert.fire({
            width: "330px",
            icon: "error",
            title: "Sorry!",
            text: err?.message,
            confirmButtonText: "Ok",
          });
        })
      }
    } else {
      SweetAlert.fire({
        width: "330px",
        icon: "error",
        title: "Sorry!",
        text: res?.data?.error || res?.message,
        confirmButtonText: "Ok",
      });
      if (res.data?.phone) {
        setErrorPhone(res.data.phone[0]);
      }
      if (res.data?.password) {
        setErrorPassword(res.data.password[0]);
      }
      if (res.data?.error) {
        setErrorPhone(res.data.error);
      }
    }
  };

  return (
    <>
      <div className="mx-auto h-screen py-6">
        <div className="w-40 mx-auto mb-5">
          <Link to="/">
            <img src={logo} className="w-full" alt="Logo" />
          </Link>
        </div>
        <div className="p-4">
          <form onSubmit={loginHandler}>
            <Input
              icon={<FiPhone />}
              label={langChange.phone}
              type="number"
              value={phone}
              event={phoneHandler}
              error={errorPhone}
              placeholder={"09xxxxxxxxx"}
            />
            <Input
              icon={<FiKey />}
              label={langChange.password}
              type="password"
              value={password}
              event={passwordHandler}
              error={errorPassword}
              placeholder={langChange.enter_password}
            />
            <div className="text-center">
              <Button
                label={langChange.login}
                type="submit"
                disable={loading}
              />
            </div>
          </form>
        </div>
        <div className={classes["forget-pass"]}>
          <Link to="/forgot-password">
            <p className={classes["forget-text"]}>
              {langChange.forgot_password}
            </p>
          </Link>
          <hr className={classes["line-through"]} />
          <Link to="/register">
            <p className={classes["forget-text"]}>
              {langChange.create_new_account}
            </p>
          </Link>
        </div>
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default Login;
