import React from 'react'
import classes from '../assets/styles/Card.module.css'
const Card = ({children}) => {
  return (
    <div className={`${classes['card']} w-[95%] md:w-[500px] mx-auto`}>
        {children}
    </div>
  )
}

export default Card;