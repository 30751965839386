import React from "react";
import noti_img from "../assets/images/icons/anounce.png";
import moment from "moment-timezone";

export default function NotificationItem({title, body}) {
  return (
    <div className="bg-[var(--primary-color)] p-3 shadow-lg rounded-lg flex items-center mb-3">
      <img src={noti_img} className="w-[30px] mx-1 mr-3 invert" alt="Noti Img" />
      <div className="w-full">
        <div className="flex justify-between border-b-[1px] mb-2 border-b-slate-600 items-center">
          <h2 className="text-slate-600 text-lg font-semibold">{title}</h2>
          <p className="text-slate-600">{moment().format('YYYY-MM-DD')}</p>
        </div>

        <p className="text-slate-600">
          {body}
        </p>
      </div>
    </div>
  );
}
