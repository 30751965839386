import React, { useState } from "react";
import Spinner from "./Spinner";
import { FiCheck, FiEdit2 } from "react-icons/fi";
import { successToaster } from "./Helper";
import { ToastContainer } from "react-toastify";

export default function SingleUserInfo({ label, value, extra_label = '' ,editAble = false, saveEditHandler}) {
  const [edit,setEdit] = useState(false);
  const [editVal, setEditVal] = useState("");

  const editHandler = () => {
    setEdit(true);
  };

  

  return (
    <>
      <div className="flex justify-between max-w-[435px] mx-auto mb-2 animate-fadein border-b border-slate-700 pb-2">
        <p className="text-black w-1/2 select-none">{label}</p>
        <p className="text-black w-1/2 flex items-center">
          <span className="text-black">:</span> 
          &nbsp; {(!value || value === 'undefined') ? 
          <Spinner /> : 
          <span className="flex items-center justify-between w-full">
            <span className="w-full select-none">
            {
              editAble ?
              (
                edit ?
                <input className="w-full bg-transparent px-2" autoFocus type="text" value={editVal} onChange={e => setEditVal(e.target.value)}/>:
                `${value} ${extra_label}`
              ):
              `${value} ${extra_label}`
            }
            </span> 
            {editAble && <span className="cursor-pointer">{edit ? <FiCheck size="20px" color="green" onClick={()=>{saveEditHandler(editVal,setEdit,setEditVal)}}/>:<FiEdit2 size="17px" color="green" onClick={editHandler}/>}</span>}</span>}
        </p>
      </div>
      <ToastContainer />
    </>
  );
}
