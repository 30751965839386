import React from 'react'
import { BrowserRouter as Router, Routes,Route} from "react-router-dom";
import Main from '../components/Main';
import GameList from '../components/GameList';
import Login from '../auths/Login';
import Register from '../auths/Register';
import RegisterVerify from '../auths/RegisterVerify';
import ForgotPassword from '../auths/ForgotPassword';
import ForgotVerify from '../auths/ForgotVerify';
import PasswordCreate from '../auths/PasswordCreate';
import ChangePassword from '../auths/ChangePassword';
import RouteGuard from '../routes/RouteGuard';
import Deposit from '../components/Deposit';
import Withdraw from '../components/Withdraw';
import Twod from '../components/Twod';
import Threed from '../components/Threed';
import Pocket from '../components/Pocket';
import Profile from '../components/Profile';
import TwodHistory from '../components/TwodHistory';
import TwodNumberList from '../components/TwodNumberList';
import BetSubmit from '../components/BetSubmit';
import Closed from '../utils/Closed';
import WinnerList from '../components/WinnerList';
import ThreedNumberList from '../components/ThreedNumberList';
import ThreedBetSubmit from '../components/ThreedBetSubmit';
import ThreedHistory from '../components/ThreedHistory';
import ThreedWinnerList from '../components/ThreedWinnerList';
import LetterAndHelp from '../components/LetterAndHelp';
import { useDispatch } from 'react-redux';
import { langTypeHandler } from '../store/slices/langSlice';
import { langChange } from '../lang';
import Promotion from '../components/Promotion';
import Notification from '../components/Notification';
import TwodHkwayHtoe from '../components/TwodHkwayHtoe';
import ContactPage from '../components/ContactPage';
import RuleAndRegulation from '../components/RuleAndRegulation';
import NotFoundPage from '../components/NotFoundPage';
import TransactionHistory from '../components/TransactionHistory';
import BurmaTwod from '../components/BurmaTwod';
import BurmaTwodHistory from '../components/BurmaTwodHistory';
import BurmaWinnerList from '../components/BurmaWinnerList';
import ScrollToTop from '../utils/ScrollToTop';
import BurmaTwodNumberList from '../components/BurmaTwodNumberList';
import BurmaTwodHkwayHtoe from '../components/BurmaTwodHkwayHtoe';
import BurmaBetSubmit from '../components/BurmaBetSubmit';
import WkThreedWinnerList from '../components/Wk3D/WkThreedWinnerList';
import WkThreedNumberList from '../components/Wk3D/WkThreedNumberList';
import WkThreedHistory from '../components/Wk3D/WkThreedHistory';
import WkThreedBetSubmit from '../components/Wk3D/WkThreedBetSubmit';
import Wk3D from '../components/Wk3D/Wk3D';
import WkTwodHkwayHtoe from '../components/Wk2D/WkTwodHkwayHtoe';
import WkTwodNumberList from '../components/Wk2D/WkTwodNumberList';
import WkBetSubmit from '../components/Wk2D/WkBetSubmit';
import WkTwodHistory from '../components/Wk2D/WkTwodHistory';
import WkWinnerList from '../components/Wk2D/WkWinnerList';
import Wk2D from '../components/Wk2D/Wk2D';

const Routers = () => {
    // for lang change
    const dispatch = useDispatch();
    const b_m_lang = localStorage.getItem("b_m_lang");
    if (b_m_lang) {
      dispatch(langTypeHandler(b_m_lang));
      langChange.setLanguage(b_m_lang);
    } else {
      localStorage.setItem("b_m_lang", "unicode");
      langChange.setLanguage("unicode");
      dispatch(langTypeHandler("unicode"));
    }
  return (
    <Router>
        <ScrollToTop />
        <Routes>
           {/* ----------- auth ---------- */}
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/verify' element={<RegisterVerify />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/verify/password' element={<ForgotVerify />} />
            <Route path='/verify/password-create' element={<PasswordCreate />} />
            <Route path='/change-password' element={<RouteGuard><ChangePassword /></RouteGuard>} />
            {/* ----------- games ---------- */}
            <Route path="/:name/:id" element={<RouteGuard><GameList /></RouteGuard>} />
            {
              ['/all/bet/burma2d','/2d3d/bet/burma2d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><BurmaTwod /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend2d','/2d3d/bet/weekend2d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Wk2D /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d','/2d3d/bet/2d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Twod /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/burma2d/time/:time/hkway-htoe','/2d3d/bet/burma2d/time/:time/hkway-htoe'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><BurmaTwodHkwayHtoe /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend2d/time/:time/hkway-htoe','/2d3d/bet/weekend2d/time/:time/hkway-htoe'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkTwodHkwayHtoe /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/time/:time/hkway-htoe','/2d3d/bet/2d/time/:time/hkway-htoe'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TwodHkwayHtoe /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/burma2d/time/:time','/2d3d/bet/burma2d/time/:time'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><BurmaTwodNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend2d/time/:time','/2d3d/bet/weekend2d/time/:time'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkTwodNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/time/:time','/2d3d/bet/2d/time/:time'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TwodNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/burma2d/time/:time/confirm','/2d3d/bet/burma2d/time/:time/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><BurmaBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend2d/time/:time/confirm','/2d3d/bet/weekend2d/time/:time/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/time/:time/confirm','/2d3d/bet/2d/time/:time/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><BetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d/number-lists/confirm','/2d3d/bet/3d/number-lists/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><ThreedBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend3d/number-lists/confirm','/2d3d/bet/weekend3d/number-lists/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkThreedBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/burma2d/history','/2d3d/bet/burma2d/history','/all/bet/burma2d/result-history','/2d3d/bet/burma2d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><BurmaTwodHistory /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend2d/history','/2d3d/bet/weekend2d/history','/all/bet/weekend2d/result-history','/2d3d/bet/weekend2d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkTwodHistory /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/history','/2d3d/bet/2d/history','/all/bet/2d/result-history','/2d3d/bet/2d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TwodHistory /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d/history','/2d3d/bet/3d/history','/all/bet/3d/result-history','/2d3d/bet/3d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><ThreedHistory /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend3d/history','/2d3d/bet/weekend3d/history','/all/bet/weekend3d/result-history','/2d3d/bet/weekend3d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkThreedHistory /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/burma2d/winner-lists','/2d3d/bet/burma2d/winner-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><BurmaWinnerList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend2d/winner-lists','/2d3d/bet/weekend2d/winner-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkWinnerList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/winner-lists','/2d3d/bet/2d/winner-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WinnerList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d/winner-lists','/2d3d/bet/3d/winner-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><ThreedWinnerList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d/number-lists','/2d3d/bet/3d/number-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><ThreedNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d','/2d3d/bet/3d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Threed /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend3d/winner-lists','/2d3d/bet/weekend3d/winner-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkThreedWinnerList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend3d/number-lists','/2d3d/bet/weekend3d/number-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><WkThreedNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/weekend3d','/2d3d/bet/weekend3d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Wk3D /></RouteGuard>} />
              )
            }
            {/* ----------- pages ---------- */}
            <Route path="/pocket" element={<RouteGuard><Pocket /></RouteGuard>} />
            <Route path="/profile" element={<><Profile /></>} />
            <Route path="/contact" element={<ContactPage />} />
            {/* ----------- Payment ---------- */}

            {
              ['/deposit','/deposit-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Deposit /></RouteGuard>} />
              )
            }
            {
              ['/withdraw','/withdraw-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Withdraw /></RouteGuard>} />
              )
            }
            {
              ['/deposit-transaction','/withdraw-transaction'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TransactionHistory /></RouteGuard>} />
              )
            }
            <Route path='/' element={<Main />} />
            <Route path='/closed' element={<RouteGuard><Closed /></RouteGuard>} />
            <Route path='/recommendation-and-help' element={<RouteGuard><LetterAndHelp /></RouteGuard>} />
            <Route path='/promotions' element={<Promotion />} />
            <Route path='/notifications' element={<RouteGuard><Notification /></RouteGuard>} />
            <Route path='/rules-and-regulations' element={<><RuleAndRegulation /></>} />
            <Route path='/*' element={<NotFoundPage />} />
        </Routes>
    </Router>
  )
}

export default Routers