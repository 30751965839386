import React from 'react'
import { motion } from 'framer-motion'
import { FiRepeat } from 'react-icons/fi'
import { langChange } from '../lang'

const DetailModel = ({ isShow, setIsShow, children, event, loading }) => {
  return (
    <>
      <div onClick={() => setIsShow(false)} className="fixed top-0 right-0 bottom-0 left-0 z-[99999] bg-[#00000082] flex items-center justify-center"
        style={{ display: isShow ? 'flex' : 'none' }}
      >
        <motion.div
          initial={{ top: isShow ? '-100px' : 0 }}
          animate={{ top: isShow ? 0 : '-100px' }}
          transition={{ duration: 0.5 }}
          className='w-[95%] md:w-[400px] relative mx-auto bg-[var(--primary-color)] p-4 rounded-2xl'
          onClick={e => e.stopPropagation()}
        >
          {children}
          {
            event ? <>
              <div className='border-t-2 mt-4 pt-4 border-neutral-300 text-center flex justify-center items-center'>
                <button disabled={loading} className='py-2 px-5 bg-[var(--accent-color)] text-[var(--text-color)] rounded-lg flex justify-center items-center' onClick={event}>
                  <FiRepeat className='text-lg text-[var(--text-color)] mr-2' />
                  {langChange.transfer}
                </button>
              </div>
            </> : <>
              <div className='border-t-2 mt-4 pt-4 border-neutral-300 text-center text-[var(--text-color)]'>
                <button className='py-2 px-5 bg-[var(--accent-color)] rounded-lg' onClick={() => setIsShow(false)}>{langChange.ok}</button>
              </div>
            </>
          }
        </motion.div>
      </div>
    </>
  )
}

export default DetailModel
