// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nav_nav__0EzPj {
  position: fixed;
  z-index: 50;
  overflow: hidden;
  border-top: 1px solid var(--light-color);
  max-width: 480px;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.Nav_nav__0EzPj ul li {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Nav_nav__0EzPj ul li.Nav_active__R25Zt a {
  color: var(--text-color);
}
.Nav_nav__0EzPj ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Nav_nav__0EzPj ul li span {
  font-size: 12px;
  display: block;
  margin-top: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Nav.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,wCAAwC;EACxC,gBAAgB;EAChB,WAAW;EACX,SAAS;EACT,QAAQ;EACR,OAAO;EACP,cAAc;AAChB;AACA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,cAAc;EACd,eAAe;AACjB","sourcesContent":[".nav {\n  position: fixed;\n  z-index: 50;\n  overflow: hidden;\n  border-top: 1px solid var(--light-color);\n  max-width: 480px;\n  width: 100%;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  margin: 0 auto;\n}\n.nav ul li {\n  font-size: 25px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.nav ul li.active a {\n  color: var(--text-color);\n}\n.nav ul li a {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.nav ul li span {\n  font-size: 12px;\n  display: block;\n  margin-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `Nav_nav__0EzPj`,
	"active": `Nav_active__R25Zt`
};
export default ___CSS_LOADER_EXPORT___;
