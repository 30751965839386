// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwodResultHistory_result-number__T58Fn {
    font-weight: bolder;
    color: var(--main-color);
    letter-spacing: .6px;
    margin-bottom: 0;
    font-size: 14px;
}
.TwodResultHistory_result__baPnQ {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;  
    color: #000;
    margin-top: 10px;
    font-size: 13px;
}
.TwodResultHistory_result__baPnQ div {
    text-align: center;
    font-weight: bolder;
    letter-spacing: .3px;
}

.TwodResultHistory_result__baPnQ p {
    margin-bottom: 0;
    font-weight: lighter;
    color: #000;
    letter-spacing: .3px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/TwodResultHistory.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,wBAAwB;IACxB,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,WAAW;IACX,oBAAoB;AACxB","sourcesContent":[".result-number {\n    font-weight: bolder;\n    color: var(--main-color);\n    letter-spacing: .6px;\n    margin-bottom: 0;\n    font-size: 14px;\n}\n.result {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;  \n    color: #000;\n    margin-top: 10px;\n    font-size: 13px;\n}\n.result div {\n    text-align: center;\n    font-weight: bolder;\n    letter-spacing: .3px;\n}\n\n.result p {\n    margin-bottom: 0;\n    font-weight: lighter;\n    color: #000;\n    letter-spacing: .3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result-number": `TwodResultHistory_result-number__T58Fn`,
	"result": `TwodResultHistory_result__baPnQ`
};
export default ___CSS_LOADER_EXPORT___;
