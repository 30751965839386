import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  
  return (
    <main className="h-screen w-full flex flex-col justify-center items-center">
      <h1 className="text-9xl font-extrabold text-[var(--main-color)] tracking-widest">404</h1>
      <div className="bg-[var(--text-color)] px-2 text-sm rounded rotate-12 absolute text-[var(--main-color)]">
        Page Not Found
      </div>
      <button className="mt-5">
        <a className="relative inline-block text-sm font-medium text-[var(--accent-color)] group focus:outline-none focus:ring">
          <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[var(--accent-color)] group-hover:translate-y-0 group-hover:translate-x-0"></span>

          <span className="relative block px-8 py-3 bg-[#1A2238] border border-current">
            <Link to="/">Return Home</Link>
          </span>
        </a>
      </button>
    </main>
  );
}
