import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DepositForm from './DepositForm';
import DepositHistory from './DepositHistory';
import Header from '../layout/Header';
import classes from '../assets/styles/Deposit.module.css';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
import Nav from '../layout/Nav';
import { IoIosArrowRoundBack } from 'react-icons/io';

const Deposit = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const { lang } = useSelector(state => state.lang)
  useEffect(()=> {}, [lang])
  const backHandler = () => navigate(-1);
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className='px-3 mt-3'>
            <button className='bg-[var(--main-color)] p-1 rounded-full' onClick={backHandler}>
              <IoIosArrowRoundBack size={'30px'}/>
            </button>
        </div>
        <div className='mx-auto h-screen'>
          <div className='p-3 pt-0'>
              <div className={classes['auth-card-header']}>
                  <span className={`text-black ${url === '/deposit' && classes.active}`} onClick={()=> navigate('/deposit')}>{langChange.deposit}</span>
                  <span className={`text-black ${url === '/deposit-history' && classes.active}`} onClick={()=> navigate('/deposit-history')}>{langChange.history}</span>
              </div>
              {
                url === '/deposit'?
                <DepositForm /> :
                <DepositHistory />
              } 
          </div>
        </div>
        <Nav />
      </div>
    </>
  )
}

export default Deposit
