import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import "../assets/styles/Banner.css";
import { BANNER_API } from "../services/constant";
import { getMethod } from "../services";
import Caption from "./Caption";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const fetchBanner = async () => {
    const res = await getMethod(`${BANNER_API}`);
    if (res.con) {
      setBanners(res.data.photos);
    }
  };
  useEffect(() => {
    fetchBanner();
  }, []);
  return (
    <>
      <div className="px-3 mt-3">
        <div className="relative">
          <div className="absolute bottom-0 z-10 w-full">
            <Caption />
          </div>
          <Swiper
            spaceBetween={10}
            pagination={false}
            modules={[Pagination, Autoplay]}
            className="mySwiper rounded-lg"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={3000}
          >
            {banners.length > 0 &&
              banners.map((banner, i) => (
                <SwiperSlide key={i}>
                  <img className={"banner-img animate-fadein"} src={banner} alt="Banner" />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Banner;
