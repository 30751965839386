// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Caption_banner-frame__nOIcB{
    height:40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    color:#fff;
    overflow: hidden;
    font-weight: 300;
    font-size: 13px;
    line-height: 40px;
    border-radius: 0 0 8px 8px;
}

/*----------------------------------------------------------------------------- */
@media (min-width: 318px) and (max-width: 480px) {
   .Caption_banner-frame__nOIcB {
    font-size: 10px;
   }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Caption.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,8BAA8B;IAC9B,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA,iFAAiF;AACjF;GACG;IACC,eAAe;GAChB;AACH","sourcesContent":[".banner-frame{\n    height:40px;\n    text-align: center;\n    background: rgba(0, 0, 0, 0.4);\n    color:#fff;\n    overflow: hidden;\n    font-weight: 300;\n    font-size: 13px;\n    line-height: 40px;\n    border-radius: 0 0 8px 8px;\n}\n\n/*----------------------------------------------------------------------------- */\n@media (min-width: 318px) and (max-width: 480px) {\n   .banner-frame {\n    font-size: 10px;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner-frame": `Caption_banner-frame__nOIcB`
};
export default ___CSS_LOADER_EXPORT___;
