import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Input from '../utils/Input';
import Button from '../utils/Button';
import classes from '../assets/styles/DepositForm.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {depositHandler} from '../store/slices/depositSlice';
import {getMethod, postMethodFormData} from '../services';
import { PAYMENT_PROVIDER_API, USER_DEPOSIT_API, USER_INFO_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import Loader from '../utils/Loader';
import PaymentList from '../utils/PaymentList';
import { copyToClipboard } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import { AiFillDollarCircle} from 'react-icons/ai';
import { FiFileText } from 'react-icons/fi';
import { langChange } from '../lang';

const DepositForm = () => {
  const [loading,setLoading] = useState(true);
  const [payments,setPayments] = useState([]);
  const [userId,setUserId] = useState(null);
  const [userBalance,setUserBalance] = useState(null);
  const [amount,setAmount] = useState("");
  const [confirmState,setConfirmState] = useState(false);
  const [transno,setTransno] = useState("");
  const navigate = useNavigate();
//   deposit status handler 
  const [minDeposit, setMinDeposit] = useState('');
  const [maxDeposit, setMaxDeposit] = useState('');
  const { pocketStatusData } = useSelector(state => state.pocketStatusSlice);

  const { lang } = useSelector(state => state.lang)
  useEffect(() => {}, [lang])
  
  useEffect(()=>{
    !pocketStatusData.deposit_status && navigate('/pocket')
    setMinDeposit(pocketStatusData.deposit_min)
    setMaxDeposit(pocketStatusData.deposit_max)
  }, [pocketStatusData])

  const [errorAmount,setErrorAmount] = useState("");
  const [errorTransno,setErrorTransno] = useState("");

  const {value} = useSelector(state => state.depositStoreSlice);
  const dispatch = useDispatch();
  const [choosePayment, setChoosePayment] = useState(null);
 

  const fetchUserInfo = async () => {
    const token = localStorage.getItem('b_m_a');
    if(token){
        const deToken = JSON.parse(token);
        let res = await getMethod(USER_INFO_API, deToken.token);
        if(res){
            if(res.con){
                setUserId(res?.data?.user_info?.id);
                setUserBalance(res?.data?.user_info?.balance);
            };
        };
    };
  };

  const fetchPaymentProviders = async () => {
      const token = localStorage.getItem('b_m_a');
      if(token){
         const deToken = JSON.parse(token);
         const res = await getMethod(`${PAYMENT_PROVIDER_API}`, deToken.token);
         if(res){
            setLoading(false);
            if(res === 'Failed to fetch'){
                setLoading(false);
                SweetAlert.fire({
                    width: '330px',
                    icon: 'error',
                    title: 'Connection!',
                    text: 'Please check internet connection!',
                    confirmButtonText: 'Ok',
                  });
            };
            if(res.con){
                setPayments(res.data);
            };
         };
      };
  };
 
  useEffect(()=>{
     fetchPaymentProviders();
     fetchUserInfo();
  },[]);

  const amountHandler = (e) => {
      setAmount(e.target.value);
      setErrorAmount("");
  };
  const customAmountHandler = e => {
    setErrorAmount("");
    setAmount(e.target.value);
    let isChecked = document.querySelectorAll('.isChecked');
    isChecked.forEach(input => {
        return input.checked = false;
    });
  };

  useEffect(()=>{
    const data = {
        user_id: userId,
        payment_provider_id: choosePayment?.id,
        amount,
        initial_balance: userBalance
    };
  dispatch(depositHandler(data))
  },[amount]);

  const nextHandler = () => {
     setErrorAmount("");
     if(value.payment_provider_id){
        if(value.amount){
             if(value.amount >= Number(minDeposit) && value.amount <= Number(maxDeposit)){
                setConfirmState(true);
             }else{
                setErrorAmount(`Deposit amount must be minimum ${minDeposit} & maximum ${maxDeposit}!`);
             }
        }else{
            setErrorAmount("Amount field is required!");
        };
     }else{
        setErrorAmount("Please choose payment type!");
     };
  };

  const depositSubmitHandler = async (e) => {
      e.preventDefault();
    //   if(slipImg){
          if(transno){
             if(transno.length === 6){
                setLoading(true);
                const token = localStorage.getItem('b_m_a');
                if(token){
                    const deToken = JSON.parse(token);
                    const formData = new FormData();
                    formData.append('payment_provider_id', value?.payment_provider_id);
                    formData.append('amount', value?.amount);
                    formData.append('transation_no', transno);
                    // slipImg.size <= 2048000 && formData.append('phone', slipImg);
                    const res = await postMethodFormData(USER_DEPOSIT_API, formData, deToken.token)
                    if(res){
                        setLoading(false);
                        if(res === 'Failed to fetch'){
                            SweetAlert.fire({
                              width: '330px',
                              icon: 'error',
                              title: 'Connection!',
                              text: 'Please check internet connection!',
                              confirmButtonText: 'Ok',
                            });
                        };
                        if(res.con){
                            SweetAlert.fire({
                                width: '330px',
                                icon: 'success',
                                title: 'Success!',
                                text: 'Deposit successful!',
                                confirmButtonText: 'Ok',
                            }).then(result => {
                                if(result.isConfirmed){
                                    navigate('/');
                                }
                            });
                        } else if (res?.data?.transation_no) {
                            SweetAlert.fire({
                                width: '330px',
                                icon: 'error',
                                title: 'Sorry!',
                                text: res.data.transation_no[0],
                                confirmButtonText: 'Ok',
                              });
                        }
                    }
                };
             }else{
                setErrorTransno("Transaction no is must be 6 digits!")
             };
          }else{
            setErrorTransno("Transaction no is required!")
          }
    //   }else{
    //     setErrorSlipImg("Slip image is required!")
    //   };
  };

  const paymentSelectHandler = (payment) => {
     setChoosePayment(payment);
  };
  return (
    <>
        <div className={classes['auth-card-body']}>
                {
                    confirmState? 
                    <>
                        <div className={classes['payment-select']}>
                            <img src={choosePayment?.photo} />
                            <div className='payment-owner-phone'>
                                <p>{choosePayment?.phone_number}</p>
                                <p>{choosePayment?.owner}</p>
                            </div>
                            <span>
                                <span className='bg-orange-500 p-[6px] pt-[4px] rounded-full cursor-pointer hover:bg-orange-600' style={{fontSize: '10px'}} onClick={e => copyToClipboard(choosePayment?.phone_number)}>{langChange.copy}</span>
                            </span>
                        </div>
                        <p className={classes['note']}>{langChange.note} : <span>{langChange.please_transfer_only_this_account}</span></p>
                        <hr style={{marginBottom: '30px',borderColor: '#2e3058'}} />
                        <div className={classes['confirm-container']}>
                            <p>
                                {langChange.amount}  &nbsp;: <span>{Number(value?.amount)?.toLocaleString('en-US')}</span>
                            </p>
                            <form onSubmit={depositSubmitHandler}>
                                {/* <Input label="Slip image" icon={<FiImage/>} type="file" event={e => {setErrorSlipImg("");setSlipImg(e.target.files[0])}} error={errorSlipImg}/> */}
                                <Input label={langChange.transaction_last_six_number} icon={<FiFileText/>} type="number" value={transno} placeholder={langChange.last_six_number} event={e => {
                                        if(e.target.value.length > 6){
                                            return;
                                        }
                                        setErrorTransno("");
                                        setTransno(e.target.value)
                                    }
                                } error={errorTransno}/>
                                <div className={classes['btn-position']}>
                                    <Button label={langChange.confirm} type="submit" bgColor="#6b67eb" bgHover="#333258" loading={loading} disable={loading}/>
                                </div>
                            </form>
                        </div>
                    </>
                    :
                    <>
                        <ul className={classes['payment-flex']}>
                            {
                                payments.length > 0 &&
                                payments.map((payment,i) => 
                                    <PaymentList key={i} data={choosePayment} id={payment.id} photo={payment.photo} event={e => paymentSelectHandler(payment)} />
                                )
                            }
                        </ul>
                        {choosePayment &&
                        <form>
                            <hr style={{marginBottom: '30px',borderColor: '#2e3058'}} />
                            <Input type="number" icon={<AiFillDollarCircle/>} label={langChange.amount} value={amount} event={customAmountHandler} error={errorAmount} placeholder={langChange.amount}/>
                            {/* <div className={classes['amount-container']}>
                                <RadioInput id="1" value="5000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="2" value="10000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="3" value="20000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="4" value="30000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="5" value="50000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="6" value="100000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="7" value="200000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="8" value="300000" amountEvent={amountHandler} checked={true}/>
                            </div> */}
                            <div className={classes['submit-position']}>
                                <Button label={langChange.next} type="button" bgColor="#6b67eb" bgHover="#333258" event={nextHandler}/>
                            </div>
                        </form>}
                    </>
                }
                
        </div>
        {
            ReactDOM.createPortal(loading && <Loader/>, document.getElementById("portal"))
        }
    </>
  )
}

export default DepositForm