import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "../utils/Button";
import { Link, useNavigate } from "react-router-dom";
import Input from "../utils/Input";
import { useSelector } from "react-redux";
import logo from "../assets/images/logo/logo.png";
import { postMethod } from "../services";
import { PASSWORD_RESET_API } from "../services/constant";
import SweetAlert from "sweetalert2";
import { FiKey } from "react-icons/fi";
import Loader from "../utils/Loader";
import { langChange } from "../lang";

const PasswordCreate = () => {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const { registerVal } = useSelector((state) => state.registerSlice);
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  useEffect(() => {
    if (!registerVal.phone) {
      navigate("/forgot-password");
    }
  }, [registerVal]);

  const newPasswordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setNewPassword(e.target.value);
    setErrorNewPassword("");
  };

  const confirmPasswordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword("");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...registerVal,
      password: newPassword,
      password_confirmation: confirmPassword,
    };
    if (newPassword === confirmPassword) {
      const res = await postMethod(PASSWORD_RESET_API, data);
      if (res) {
        setLoading(false);
        if (res === "Failed to fetch") {
          SweetAlert.fire({
            width: "330px",
            icon: "error",
            title: "Connection!",
            text: "Please check internet connection!",
            confirmButtonText: "Ok",
          });
        }
        if (res?.con) {
          SweetAlert.fire({
            width: "330px",
            icon: "success",
            title: "Success!",
            text: "Forgot password successful!",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
        } else {
          if (res?.data?.error) {
            setErrorNewPassword(res.data.error);
            navigate(-1);
          }
        }
      }
    } else {
      setErrorNewPassword("Password and confirm password must match");
      setErrorConfirmPassword("Password and confirm password must match");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (newPassword === confirmPassword) {
      setErrorConfirmPassword("");
      setErrorNewPassword("");
    } else {
      setErrorConfirmPassword("Your password is does not match");
    }
  }, [confirmPassword, newPassword]);

  return (
    <>
      <div className="mx-auto py-6 h-screen">
        <div>
          <div className="w-40 mx-auto mb-3">
            <Link to="/">
              <img src={logo} className="w-full" />
            </Link>
          </div>
          <div className="p-4">
            <form onSubmit={submitHandler}>
              <Input
                icon={<FiKey />}
                label={langChange.new_password}
                type="password"
                value={newPassword}
                event={newPasswordHandler}
                error={errorNewPassword}
                placeholder={langChange.enter_new_password}
              />
              <Input
                icon={<FiKey />}
                label={langChange.confirm_password}
                type="password"
                value={confirmPassword}
                event={confirmPasswordHandler}
                error={errorConfirmPassword}
                placeholder={langChange.enter_new_password}
              />
              <div className="text-center">
                <Button
                  label={langChange.confirm}
                  type="submit"
                  disable={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default PasswordCreate;
