import React, { useEffect } from 'react'
import classes from '../assets/styles/Loader.module.css';
import { useSelector } from 'react-redux';

const Loader = () => {
  const { lang } = useSelector(state => state.lang)
  useEffect(() => {}, [lang])
  return (
    <div className={classes['loader-container']}>
        <span className={classes.loader}></span>
    </div>
  )
}

export default Loader
