import React, { useEffect, useState } from 'react';
import classes from '../assets/styles/Number.module.css';

const Number = ({number,id,disable,event,activeArr,percentage,color,status}) => {
  const [checkStatus,setCheckStatus] = useState(false);
  useEffect(()=>{
     setCheckStatus(activeArr);
  },[activeArr]);

  return (
      <div>
          <input className={classes['number-checked']} type='checkbox' checked={checkStatus} disabled={disable} id={id} onChange={(e)=>event(e,number,checkStatus,setCheckStatus)}/>
          <label className={`${classes.number} ${(!status || Math.trunc(percentage) >= 100) && classes.closed_number} text-[15px] lg:text-[18px]`} htmlFor={id} style={{background: !status && '#909090', color: !status && '#fff'}}>
              <span>{number}</span>
              {
                status && <span className={classes['percent-bar']}>
                <span style={{width: `${status ? Math.trunc(percentage) : 0}%`,backgroundColor: color === 'red' ? 'red' : color === 'yellow' ? 'orange' : 'forestgreen'}} className={` h-[4px] rounded-sm absolute left-0 inline-block`}></span>
                </span>
              }
          </label>
      </div>
  )
}

export default Number
