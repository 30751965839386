import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "../assets/styles/ProviderList.module.css";
import Layout from "../utils/Layout";
import { useNavigate } from "react-router-dom";
import ProviderCard from "../utils/ProviderCard";
import { getMethod } from "../services";
import { PROVIDER_CONNECT_API, PROVIDER_API } from "../services/constant";
import SweetAlert from "sweetalert2";
import Loader from "../utils/Loader";
import twodImg from "../assets/images/mm_2d.png";
import bmTwodImg from "../assets/images/bm_2d.png";
import threedImg from "../assets/images/mm_3d.png";

const ProviderList = () => {
  const [loading, setLoading] = useState(true);
  const [providerConnectLists, setProviderConnectLists] = useState([]);
  const [providers, setProviders] = useState([]);
  const [allActive, setAllActive] = useState(null);
  const [active, setActive] = useState(null);
  const navigate = useNavigate();
  const [removedProviders, setRemovedProviders] = useState([]);

  const fetchProviderConnect = async () => {
    const res = await getMethod(
      `${PROVIDER_CONNECT_API}?status=active&website=true`
    );
    if (res) {
      setLoading(false);
      if (res === "Failed to fetch") {
        SweetAlert.fire({
          width: "330px",
          icon: "error",
          title: "Connection!",
          text: "Please check internet connection!",
          confirmButtonText: "Ok",
        });
      }
      if (res.con) {
        let twoThreeD = [
          {
            id: "23d",
            name: "2D 3D",
            photo: "",
            status: "active",
            types: [
              {
                id: "2d",
                provider: {
                  id: "2d",
                  name: "2D",
                  full_name: "2D",
                  photo: twodImg,
                },
                provider_id: "2d",
                provider_type_id: "2d",
                type: "2d3d/bet",
              },
              {
                id: "3d",
                provider: {
                  id: "3d",
                  name: "3D",
                  full_name: "3D",
                  photo: threedImg,
                },
                provider_id: "3d",
                provider_type_id: "3d",
                type: "2d3d/bet",
              },
              {
                id: "burma2d",
                provider: {
                  id: "burma2d",
                  name: "Burma 2D",
                  full_name: "Burma 2D",
                  photo: bmTwodImg,
                },
                provider_id: "burma2d",
                provider_type_id: "burma2d",
                type: "2d3d/burma2d/bet",
              },
              {
                id: "weekend2d",
                provider: {
                  id: "weekend2d",
                  name: "Weekend 2D",
                  full_name: "Weekend 2D",
                  photo: bmTwodImg,
                },
                provider_id: "weekend2d",
                provider_type_id: "weekend2d",
                type: "2d3d/weekend2d/bet",
              },
              {
                id: "weekend3d",
                provider: {
                  id: "weekend3d",
                  name: "Weekend 3D",
                  full_name: "Weekend 3D",
                  photo: threedImg,
                },
                provider_id: "weekend3d",
                provider_type_id: "weekend3d",
                type: "2d3d/weekend3d/bet",
              }
            ],
          },
        ];
        setProviderConnectLists([
          ...twoThreeD,
          ...res.data.filter((el) => el.name === "Slots" || el.name === 'Sports' || el.name === 'Live'),
        ]);
        setAllActive("all");
        setActive(null);
      }
    }
  };

  const fetchProvider = async () => {
    const token = JSON.parse(localStorage.getItem("b_m_a"));
    const res = await getMethod(`${PROVIDER_API}?status=active&website=true`);
    if (res) {
      setLoading(false);
      if (res.con) {
        let data = [
          {
            id: "bet/2d",
            full_name: "2D",
            name: "2D",
            photo: twodImg,
            status: "active",
          },
          {
            id: "bet/3d",
            full_name: "3D",
            name: "3D",
            photo: threedImg,
            status: "active",
          },
          {
            id: "bet/burma2d",
            full_name: "Burma 2D",
            name: "Burma 2D",
            photo: bmTwodImg,
            status: "active",
          },
          {
            id: "bet/weekend2d",
            full_name: "Weekend 2D",
            name: "Weekend 2D",
            photo: bmTwodImg,
            status: "active",
          },
          {
            id: "bet/weekend3d",
            full_name: "Weekend 3D",
            name: "Weekend 3D",
            photo: threedImg,
            status: "active",
          }
        ];
        res?.data?.sort((a,b) => a.id - b.id);
        setProviders([...data, ...res.data]);
        setAllActive("all");
        setActive(null);
      }
    }
  };
  useEffect(() => {
    fetchProviderConnect();
    fetchProvider();
  }, []);


  const gamesListHandler = (provider) => {
    switch(provider?.full_name){
        case "Burma 2D":
          return navigate(`/all/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "Weekend 2D":
          return navigate(`/all/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "Weekend 3D":
          return navigate(`/all/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "2D":
          return navigate(`/all/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "3D":
          return navigate(`/all/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "BTI Sportsbook":
          return navigate(`/football/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "Jili":
          return navigate(`/fish/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "KING MAKER":
          return navigate(`/slot/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "Pragmatic Play":
          return navigate(`/slot/${provider.provider_id ? provider.provider_id : provider.id}`)
        case "Joker":
          return navigate(`/slot/${provider.provider_id ? provider.provider_id : provider.id}`)
    };
  };

  useEffect(() => {
    let temp = [];
    if (providers?.length > 0) {
      providers.map((el) => {
        if (
          el.full_name === "Burma 2D" ||
          el.full_name === "Weekend 2D" ||
          el.full_name === "Weekend 3D" ||
          el.full_name === "2D" ||
          el.full_name === "3D" ||
          el.full_name === "Pragmatic Play" ||
          el.full_name === "KING MAKER" ||
          el.full_name === "BTI Sportsbook" ||
          el.full_name === "Jili" ||
          el.full_name === "Joker"
        ) {
          temp.push(el);
        }
      });
      setRemovedProviders(temp);
    }
  }, [providers]);

  return (
    <>
      <Layout>
        <div className="mt-3">
          <ul className="flex items-center justify-center flex-wrap w-full">
            {
              removedProviders.length > 0 &&
                removedProviders.map((provider, i) => (
                  <ProviderCard
                    key={i}
                    name={provider?.full_name}
                    image={provider?.photo}
                    event={(e) => gamesListHandler(provider)}
                  />
                ))
            }
          </ul>
        </div>
      </Layout>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default ProviderList;
