import SweetAlert from 'sweetalert2';

export const getMethod = async (api, token = "") => {
    try {
        const fetchApi = await fetch(api, {
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });
        const res = await fetchApi.json();
        if (res.status === 'success' || res.success) {
            return {
                con: true,
                data: res.data,
                meta: res.meta,
                status: res.status,
            }
        } else {
            if (res.message === 'Unauthenticated.') {
                localStorage.removeItem('b_m_a');
            };
            return {
                con: false,
                data: res.data,
                meta: res.meta,
                status: res.status,
            }
        }
    }
    catch (err) {
        return err.message;
    }
}

export const postMethod = async (api, data, token = "") => {
    try {
        const fetchApi = await fetch(api, {
            method: 'POST',
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        });
        const res = await fetchApi.json();
        if (res.message === 'Unauthenticated.') {
            localStorage.removeItem('b_m_a');
        };
        if (res.status === 'success' || res.success) {
            return {
                con: true,
                data: res.data,
                message: res?.message
            };
        } else {
            if (res?.data?.[Object.keys(res.data)[0]]) {
                SweetAlert.fire({
                    width: '330px',
                    icon: 'warning',
                    title: 'Sorry!',
                    text: res?.data?.[Object.keys(res.data)[0]] || "Somethin wrong!",
                })
                return {
                    con: false,
                    data: res.data,
                };
            } 
            if (res?.data?.error) {
                SweetAlert.fire({
                    width: '330px',
                    icon: 'warning',
                    title: 'Sorry!',
                    text: res.data.error || "Somethin wrong!",
                })
                return {
                    con: false,
                    data: res.data,
                };
            } else {
                SweetAlert.fire({
                    width: '330px',
                    icon: 'error',
                    title: 'Sorry!',
                    text: res?.error ? res?.error : res?.message || "Somethin wrong!",
                })
                return {
                    con: false,
                    data: res.data,
                };
            }
        };
    }
    catch (err) {
        return err.message
    }
};

export const postMethodFormData = async (api, formData, token = "") => {
    try {
        const fetchApi = await fetch(api, {
            method: 'POST',
            headers: {
                "accept": "multipart/form-data",
                "Authorization": `Bearer ${token}`,
            },
            body: formData
        });
        const res = await fetchApi.json();
        if (res.message === 'Unauthenticated.') {
            localStorage.removeItem('b_m_a');
        };
        if (res.status === 'success' || res.success) {
            return {
                con: true,
                data: res.data
            };
        } else {
            SweetAlert.fire({
                width: '330px',
                icon: 'error',
                title: 'Error!',
                text: res.message || "Somethin wrong!",
            });
            return {
                con: false,
                data: res.data
            };
        };
    }
    catch (err) {
        return err.message
    }
};
export const deleteMethod = async (api, data, token = "") => {
    try {
        const fetchApi = await fetch(api, {
            method: 'DELETE',
            headers: {
                "accept": "multipart/form-data",
                "Authorization": `Bearer ${token}`,
            },
            body: data
        });
        const res = await fetchApi.json();
        if (res.message === 'Unauthenticated.') {
            localStorage.removeItem('b_m_a');
        };
        if (res.status === 'success' || res.success) {
            return {
                con: true,
                data: res.data
            };
        } else {
            SweetAlert.fire({
                width: '330px',
                icon: 'error',
                title: 'Error!',
                text: res.message || "Somethin wrong!",
            });
            return {
                con: false,
                data: res.data
            };
        };
    }
    catch (err) {
        return err.message
    }
};
