import React from "react";
const Layout = ({ children }) => {
  return (
    <div className="container mx-auto px-2 mt-2">
      {children}
    </div>
  );
};

export default Layout;
