import SweetAlert from 'sweetalert2';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function copyToClipboard(copyMe) {
  await navigator.clipboard.writeText(copyMe);
  SweetAlert.fire({
      icon: 'success',
      width: 300,
      title: 'Success',
      text: "Copy to Clipboard",
  })
};

export const errorToaster = (message,toastId) => {
  if(!toast.isActive(toastId.current)){
    toastId.current = toast.error(message, {
        position: "top-center",
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        hideProgressBar: true,
        theme: 'colored'
    });
  };
};

export const successToaster = (message,toastId) => {
  if(!toast.isActive(toastId.current)){
    toastId.current = toast.success(message, {
        position: "top-center",
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        hideProgressBar: true,
        theme: 'colored'
    });
  };
};

export const bytesToSize = bytes => {
  const kilobytes = bytes / 1024;
  const megabytes = bytes / (1024 * 1024);

  return {
      bytes: bytes,
      kilobytes: kilobytes.toFixed(2),
      megabytes: megabytes.toFixed(2)
  };
};

export const getThreeLotteryTime = (closingDate) => {
  const closing_date = new Date(closingDate).getDate();
  let lottery_time;
  if (parseInt(closing_date) <= 19 && parseInt(closing_date) >= 15) {
    lottery_time = "One";
  }
  if (parseInt(closing_date) >= 29 || parseInt(closing_date) <= 3) {
    lottery_time = "Two";
  }
  return lottery_time;
};