import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Header from "../../layout/Header";
import Number from "../../utils/Number";
import classes from "../../assets/styles/ThreedNumberList.module.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { errorToaster, getThreeLotteryTime } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { betNumbersHandler } from "../../store/slices/betNumbersSlice";
import { getMethod } from "../../services";
import { THREE_D_CLOSED_STATUS_API, THREE_D_LIMIT_API, THREE_D_LOTTERY_CLOSE_API, THREE_D_STATUS_API, WK_3D_CLOSE_API } from "../../services/constant";
import SweetAlert from "sweetalert2";
import Nav from "../../layout/Nav";
import Loader from "../../utils/Loader";

let tempNums = [];
let arrTempNums = [];
let closedTemp = [];
const WkThreedNumberList = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [threedNumbers, setThreedNumbers] = useState([]);
  const [arrStatus, setArrStatus] = useState(false);
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const [closedNums, setClosedNums] = useState([]);
  const { value } = useSelector((state) => state.userInfoSlice);

  const [initial, setInitial] = useState(0);
  const [optionNums, setOptionNums] = useState([]);
  const [chooseNums, setChooseNums] = useState([]);
  const [showNums, setShowNums] = useState([]);

  const token = JSON.parse(localStorage.getItem("b_m_a"));

  const [lotteryLoading, setLotteryLoading] = useState(false);
  const [lotteryRange, setLotteryRange] = useState({});
  const fetchLotteryRange = async () => {
    setLotteryLoading(true);
    await getMethod(THREE_D_LOTTERY_CLOSE_API + '?type=Weekend').then(res => {
      setLotteryLoading(false);
      if (res?.con) {
        setLotteryRange(res?.data);
      }
    })
  }
  useEffect(() => {
    fetchLotteryRange();
  }, []);

  const fetchThreeDStatus = async () => {
    if (token) {
      const res = await getMethod(WK_3D_CLOSE_API, token.token);
      res.status !== 200 &&
        SweetAlert.fire({
          width: "330px",
          icon: "warning",
          title: "Sorry!",
          text: `သတ်မှတ်ထီထိုးချိန် ကျော်လွန်သွားပါပြီ။`,
          confirmButtonText: "Ok",
        }).then(() => {
          navigate(-1);
        });
    }
  };

  useEffect(() => {
    fetchThreeDStatus();
  }, []);

  useEffect(() => {
    closedNums.length > 0 &&
      SweetAlert.fire({
        width: "330px",
        icon: "warning",
        title: "Sorry!",
        text: `( ${[...closedNums]} ) ${
          closedNums.length > 1 ? "are" : "is"
        } not avaliable!`,
        confirmButtonText: "Ok",
      }).then(() => {
        setClosedNums([]);
        closedTemp = [];
      });
  }, [closedNums]);

  const closedFilter = (initialArray) => {
    closedTemp = [];
    initialArray.map((num1) => {
      let filterClosed = tempNums.find((num2) => num2.number == num1.number);
      !filterClosed && closedTemp.push(num1.number);
    });
    setClosedNums(closedTemp);
  };

  // for permutation
  const permut = (number) => {
    const digits = number.toString().split("");
    const permutations = [];
    const generatePermut = (current, remaining) => {
      if (remaining.length === 0) {
        !permutations.includes(current.join("")) &&
          permutations.push(current.join(""));
        return;
      }
      for (let i = 0; i < remaining.length; i++) {
        const newCurrent = current.concat(remaining[i]);
        const newRemaining = remaining
          .slice(0, i)
          .concat(remaining.slice(i + 1));
        generatePermut(newCurrent, newRemaining);
      }
    };
    generatePermut([], digits);
    return permutations;
  };

  const fetchThreeDLimit = async () => {
      // if (currentDate) {
      let temp = [];
      let tempOptionNums = [];
      for (let i = 0; i < 10; i++) {
        tempOptionNums.push(i * 100);
      }
      setOptionNums(tempOptionNums);
      for (let i = 0; i < 1000; i++) {
        if (i < 10) {
          temp.push(`00${i}`);
        } else if (i < 100) {
          temp.push(`0${i}`);
        } else {
          temp.push(`${i}`);
        }
      }
      // switch (+initial) {
      //    case 0:
      //       for (let i = 0; i < 100; i++) {
      //          if (i < 10) {
      //             temp.push(`00${i}`)
      //          } else if (i < 100) {
      //             temp.push(`0${i}`)
      //          } else {
      //             temp.push(`${i}`)
      //          }
      //       }
      //       break;
      //    case 100:
      //       for (let i = 0; i < 100; i++) {
      //          temp.push(+initial + i)
      //       }
      //       break;
      //    default:
      //       for (let i = 0; i < 100; i++) {
      //          temp.push(+initial + i)
      //       }
      //       break;
      // }
      if (token) {
        const res = await getMethod(`${THREE_D_LIMIT_API}?is_digit=${initial/100}&type=Weekend`, token.token)
        if (res.con) {
          let limitFilterNums = [];
          for (let i = 0; i < temp.length; i++) {
            const matchingNumber = res.data.find(
              (num) => num.number == temp[i]
            );
            if (matchingNumber) {
              limitFilterNums.push({
                number: matchingNumber.number,
                color: matchingNumber.color,
                percentage: matchingNumber.percentage,
                status: matchingNumber.status,
              });
            } else {
              limitFilterNums.push({
                number: temp[i],
                color: "yellow",
                percentage: 0.0,
                status: true,
              });
            }
          }
          const closeRes = await getMethod(THREE_D_CLOSED_STATUS_API + '?type=Weekend', token.token)
          if (closeRes.con) {
            let closedResNums = [];
            limitFilterNums.map((num) => {
              const finder = closeRes.data.find(
                (num2) => num2.number == num.number
              );
              if (finder) {
                closedResNums.push({
                  number: num.number,
                  color: num.color,
                  percentage: num.percentage,
                  status: false,
                });
              } else {
                closedResNums.push(num);
              }
            });
            setThreedNumbers(closedResNums);
          }
        }
      } else {
        navigate("/login");
      }
  };

  useEffect(() => {
    fetchThreeDLimit();
  }, [initial]);

  const numberHandler = (event, number, checkStatus, setCheckStatus) => {
    if (!arrStatus) {
      setCheckStatus(!checkStatus);
      let isChecked = event.target.checked;
      if (isChecked) {
        !tempNums.includes({ number: number }) &&
          tempNums.push({ number: number });
        setChooseNums(tempNums);
      } else {
        tempNums = tempNums.filter(el => `${el.number}` !== `${number}`)
        setChooseNums(tempNums);
      }
    }
  };

  useEffect(() => {
    if (threedNumbers.length > 0) {
      switch (+initial) {
        case 0:
          arrTempNums = [];
          for (let i = 0; i < 100; i++) {
            arrTempNums.push(threedNumbers[i]);
          }
          setShowNums(arrTempNums);
          break;
        case 100:
          arrTempNums = [];
          for (let i = +initial; i < +initial + 100; i++) {
            arrTempNums.push(threedNumbers[i]);
          }
          setShowNums(arrTempNums);
          break;
        default:
          arrTempNums = [];
          for (let i = +initial; i < +initial + 100; i++) {
            arrTempNums.push(threedNumbers[i]);
          }
          setShowNums(arrTempNums);
          break;
      }
    }
  }, [threedNumbers, initial]);

  const arrStatusHandler = () => {
    chooseNums.length > 0
      ? setArrStatus(!arrStatus)
      : errorToaster("Please choose number first!", toastId);
  };

  useEffect(() => {
    if (tempNums.length > 0) {
      if (arrStatus) {
        const tempR = tempNums.map((num) => permut(num.number));
        const afterR = [];
        const initialArr = [];
        for (let i = 0; i < tempR.length; i++) {
          tempR[i].map((num1) => {
            const finder = threedNumbers.find((num2) => num2.number == num1);
            if (finder) {
              if (finder.status && finder.percentage !== 100) {
                afterR.push({ number: finder.number });
                tempNums.push({ number: finder.number });
              }
            }
            initialArr.push({ number: num1 });
          });
        }
        closedFilter(initialArr);
        setChooseNums(afterR);
      } else {
        chooseNums.map((num) => {
          const index = tempNums.lastIndexOf(num);
          tempNums.splice(index, 1);
        });
        setChooseNums(tempNums);
      }
    }
  }, [arrStatus]);

  const setAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setAmount(amount);
  };

  const submitHandler = () => {
    const submitNums = [];
    
    const totalAmount = chooseNums.length * amount;
    if (chooseNums.length > 0) {
      if (amount) {
        if (value?.user_info?.current_balance >= totalAmount) {
          if (amount >= 100) {
            chooseNums.map((num, i) =>
              submitNums.push({
                id: i + 1,
                number: num.number,
                amount,
                time: getThreeLotteryTime(lotteryRange?.closing_time),
              })
            );
            if (submitNums.length > 0) {
              dispatch(betNumbersHandler(submitNums));
              navigate("confirm");
              setChooseNums([]);
              tempNums = [];
            }
          } else {
            errorToaster("Minium bet amount is 100", toastId);
          }
        } else {
          errorToaster("Balance insufficient to bet!", toastId);
        }
      } else {
        errorToaster("Amount is required", toastId);
      }
    } else {
      errorToaster("Please choose bet numbers", toastId);
    }
  };

  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className="min-h-[70vh]">
          <div className="container mx-auto">
            <div className="p-3 mb-10">
              <div className="mb-3">
                  <div className="flex items-center justify-between mb-3">
                    <div className="">
                      <select
                        className="px-3 h-[40px] bg-[var(--main-color)] text-sm border border-[var(--main-color)] rounded-lg"
                        onChange={(e) => setInitial(e.target.value)}
                      >
                        {optionNums.length > 0 &&
                          optionNums.map((num, i) => (
                            <option key={i} value={num}>
                              {num === 0 ? "000" : num} မှစ၍
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="flex items-center">
                    <input
                      className={`p-2 bg-[var(--primary-color)] ${classes["amount-insert"]}`}
                      type="text"
                      placeholder="Minium 100"
                      value={amount}
                      onChange={setAmountHandler}
                    />
                    <button
                      className="border border-[var(--main-color)] bg-[var(--main-color)] text-[var(--text-color)] transition-[1s] rounded-e-lg py-2 px-3"
                      onClick={submitHandler}
                    >
                      ထိုးမည်
                    </button>
                    {/* <input
                      className={classes.arr}
                      id="ar"
                      type="checkbox"
                      checked={arrStatus}
                      onChange={arrStatusHandler}
                    />
                    <label htmlFor="ar" className={classes.arr_label}>
                      R
                    </label> */}
                  </div>
                  
                </div>
              </div>
              <ul className="flex items-center justify-center flex-wrap">
                {showNums.length > 0 &&
                  showNums.map((num, i) => (
                    <li key={i} className="p-[6px]">
                      <Number
                        number={num.number}
                        id={i}
                        event={numberHandler}
                        disable={
                          arrStatus ||
                          !num.status ||
                          Math.trunc(num.percentage) >= 100
                        }
                        activeArr={
                          chooseNums.length > 0
                            ? chooseNums.some((el) => el.number == num.number)
                            : false
                        }
                        showNums={showNums}
                        percentage={num.percentage > 100 ? 100 : num.percentage}
                        color={num.color}
                        status={num.status}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <Nav />
      </div>
      <ToastContainer limit={1} />
      {createPortal(
        (isLoading || lotteryLoading) && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default WkThreedNumberList;
