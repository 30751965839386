// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input-group__c4m-- {
  margin-bottom: 20px;
}
.Input_input-label__Ie3Wt {
  display: block;
  margin-bottom: 6px;
  font-size: small;
  color: #000;
}
.Input_input-container__NW9Fo {
  position: relative;
}
.Input_input-icon__rwJp2 {
  position: absolute;
  font-size: 17px;
  top: 50%;
  transform: translate(0, -50%);
  left: 15px;
  border-right: 1px solid #131d445c;
  padding-right: 10px;
  color: grey;
}
.Input_input-box__JTTB2 {
  width: 100%;
  padding: 8px 0 8px 55px;
  outline: none;
  background-color: #e7e7e73d;
  /* background-color: #3A55C8; */
  /* box-shadow : -50px 0px 15px 2px #1F2E6A inset; */
  border-radius: 3px;
  color: grey;
  font-size: 16px;
  border: 1px solid #afafaf;
}
.Input_password-toggle-icon__ykgdS {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: grey;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -45%);
}
.Input_error-message__VpgO\\+ {
  margin-top: 5px;
  display: block;
  font-size: 14px;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Input.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,QAAQ;EACR,6BAA6B;EAC7B,UAAU;EACV,iCAAiC;EACjC,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,2BAA2B;EAC3B,+BAA+B;EAC/B,mDAAmD;EACnD,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,6BAA6B;AAC/B;AACA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,UAAU;AACZ","sourcesContent":[".input-group {\n  margin-bottom: 20px;\n}\n.input-label {\n  display: block;\n  margin-bottom: 6px;\n  font-size: small;\n  color: #000;\n}\n.input-container {\n  position: relative;\n}\n.input-icon {\n  position: absolute;\n  font-size: 17px;\n  top: 50%;\n  transform: translate(0, -50%);\n  left: 15px;\n  border-right: 1px solid #131d445c;\n  padding-right: 10px;\n  color: grey;\n}\n.input-box {\n  width: 100%;\n  padding: 8px 0 8px 55px;\n  outline: none;\n  background-color: #e7e7e73d;\n  /* background-color: #3A55C8; */\n  /* box-shadow : -50px 0px 15px 2px #1F2E6A inset; */\n  border-radius: 3px;\n  color: grey;\n  font-size: 16px;\n  border: 1px solid #afafaf;\n}\n.password-toggle-icon {\n  background-color: transparent;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  color: grey;\n  position: absolute;\n  right: 10px;\n  top: 50%;\n  transform: translate(0, -45%);\n}\n.error-message {\n  margin-top: 5px;\n  display: block;\n  font-size: 14px;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-group": `Input_input-group__c4m--`,
	"input-label": `Input_input-label__Ie3Wt`,
	"input-container": `Input_input-container__NW9Fo`,
	"input-icon": `Input_input-icon__rwJp2`,
	"input-box": `Input_input-box__JTTB2`,
	"password-toggle-icon": `Input_password-toggle-icon__ykgdS`,
	"error-message": `Input_error-message__VpgO+`
};
export default ___CSS_LOADER_EXPORT___;
