import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import PromotionItem from "./PromotionItem";
import PromotionDetail from "./PromotionDetail";
import { getMethod } from "../services";
import { PROMOTION_API } from "../services/constant";
import Loader from "../utils/Loader";
import { langChange } from "../lang";
import NoData from "../utils/NoData";
import Layout from "../utils/Layout";
import Nav from "../layout/Nav";
import {IoIosArrowRoundBack} from 'react-icons/io';

export default function Promotion() {
  const [showDetail, setShowDetail] = useState({});
  const [promotions, setPromotions] = useState([]);
  const [isLoading, setIsLoaing] = useState(false);

  const fetchPromo = async () => {
    setIsLoaing(true);
    const res = await getMethod(PROMOTION_API);
    res && setIsLoaing(false);
    res?.con && setPromotions(res?.data);
  };

  useEffect(() => {
    fetchPromo();
  }, []);
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className="h-[80vh] overflow-auto animate-fadein">
          <Layout type={langChange.promotion}>
              <div className="flex flex-wrap">
                {promotions?.length > 0 ? (
                  promotions?.map((promotion) => (
                    <PromotionItem
                      data={promotion}
                      key={promotion.id}
                      setShowDetail={() => setShowDetail(promotion)}
                    />
                  ))
                ) : (
                  <NoData />
                )}
              </div>
          </Layout>
        </div>
        <Nav />
      </div>
      <PromotionDetail
          showDetail={showDetail?.id ? true : false}
          promotion={showDetail}
          setShowDetail={() => setShowDetail({})}
          close={e => {
            e.target === e.currentTarget && setShowDetail({});
          }}
        />
        {isLoading && <Loader />}
    </>
  );
}
