import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getMethod } from "../services";
import { WITHDRAW_HISTORY_API } from "../services/constant";
import Loader from "../utils/Loader";
import SweetAlert from "sweetalert2";
import NoData from "../utils/NoData";
import TransactionCard from "./TransactionCard";

const WithdrawHistory = () => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);

  const fetchHistory = async () => {
    const token = localStorage.getItem("b_m_a");
    if (token) {
      const deToken = JSON.parse(token);
      const res = await getMethod(
        `${WITHDRAW_HISTORY_API}?user_id=${deToken.user_id}`,
        deToken.token
      );
      if (res) {
        setLoading(false);
        if (res === "Failed to fetch") {
          SweetAlert.fire({
            width: "330px",
            icon: "error",
            title: "Connection!",
            text: "Please check internet connection!",
            confirmButtonText: "Ok",
          });
        }
        if (res.con) {
          setHistory(res.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className="mt-4 h-[600px] overflow-auto">
          {history.length > 0 ? (
            history.map(hist => (
              <TransactionCard type="withdraw" data={hist} key={hist.id} />
            ))
          ) : (
            <NoData />
          )}
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </div>
  );
};
export default WithdrawHistory;
