import React, { useEffect } from 'react'
import classes from '../assets/styles/Nav.module.css';
import { Link } from 'react-router-dom';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
import home from '../assets/images/home.png'
import promotion from '../assets/images/promotion.png'
import notification from '../assets/images/notification.png'
import wallet from '../assets/images/wallet.png'
import profile from '../assets/images/profile.png'
import contact_image from "../assets/images/contact_me.png";

const Nav = () => {
    const route = window.location.pathname;
    const { lang } = useSelector(state => state.lang);
    useEffect(() => {}, [lang]);
    const getAuth = localStorage.getItem('b_m_a');

    
    return (
        <div className={`${classes.nav} mt-4`}>
            <ul className='flex bg-[var(--main-color)] justify-center'>
                <li className={`w-2/6 whitespace-nowrap ${route === "/" && classes.active}`}>
                    <Link to="/" className='py-[10px]'>
                        <img className='w-[25px]' src={home} alt="Home" />
                        <span>{langChange.home}</span>
                    </Link>
                </li>
                {
                    getAuth &&
                    <>
                        <li className={`w-2/6 whitespace-nowrap ${route === "/promotions" && classes.active}`}>
                            <Link to="/promotions" className='py-[10px]'>
                            <img className='w-[25px]' src={promotion} alt="Promo" />
                                <span>{langChange.promotion}</span>
                            </Link>
                        </li>
                        <li className={`w-2/6 whitespace-nowrap ${(route === "/pocket" || route === "/deposit" || route === "/withdraw") && classes.active}`}>
                            <Link to="/pocket" className='py-[10px]'>
                            <img className='w-[25px]' src={wallet} alt="Wallet" />
                                <span>{langChange.pocket}</span>
                            </Link>
                        </li>
                        <li className={`w-2/6 whitespace-nowrap ${route === "/notifications" && classes.active}`}>
                            <Link to="/notifications" className='py-[10px]'>
                                <img className='w-[25px]' src={notification} alt="Noti" />
                                <span>{langChange.notification}</span>
                            </Link>
                        </li>
                    </>
                }
                {
                    !getAuth &&
                    <li className={`w-2/6 whitespace-nowrap ${route === "/contact" && classes.active}`}>
                        <Link to="/contact" className='py-[10px]'>
                            <img className='w-[25px]' src={contact_image} alt="Profile" />
                            <span>{langChange.contact}</span>
                        </Link>
                    </li>
                }
                <li className={`w-2/6 whitespace-nowrap ${route === "/profile" && classes.active}`}>
                    <Link to="/profile" className='py-[10px]'>
                        <img className='w-[25px]' src={profile} alt="Profile" />
                        <span>{langChange.profile}</span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Nav
