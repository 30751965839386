// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPassword_login-container__tYd-9 {
    margin-top: 30px;
}
.ForgotPassword_forget-pass__gkeI8 {
    width: 220px;
    margin: 20px auto 0 auto;
}
.ForgotPassword_forget-text__Jktvp {
    text-align: center;
    color: #000;
}
.ForgotPassword_line-through__SCz\\+R {
    border: 1px solid var(--accent-color);
    margin: 3px 0;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ForgotPassword.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,wBAAwB;AAC5B;AACA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,qCAAqC;IACrC,aAAa;AACjB","sourcesContent":[".login-container {\n    margin-top: 30px;\n}\n.forget-pass {\n    width: 220px;\n    margin: 20px auto 0 auto;\n}\n.forget-text {\n    text-align: center;\n    color: #000;\n}\n.line-through {\n    border: 1px solid var(--accent-color);\n    margin: 3px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-container": `ForgotPassword_login-container__tYd-9`,
	"forget-pass": `ForgotPassword_forget-pass__gkeI8`,
	"forget-text": `ForgotPassword_forget-text__Jktvp`,
	"line-through": `ForgotPassword_line-through__SCz+R`
};
export default ___CSS_LOADER_EXPORT___;
