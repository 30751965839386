// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_photoOverlay__N-r4g {
    z-index: 10;
}
.Profile_photoOverlay__N-r4g::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 11;
    background-color: #0000001f;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Profile.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".photoOverlay {\n    z-index: 10;\n}\n.photoOverlay::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    z-index: 11;\n    background-color: #0000001f;\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photoOverlay": `Profile_photoOverlay__N-r4g`
};
export default ___CSS_LOADER_EXPORT___;
