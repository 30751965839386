import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useSelector } from "react-redux";
import noteImg from "../assets/images/icons/history.png";
import prizeImg from "../assets/images/icons/winner.png";
import numberImg from "../assets/images/icons/threed.png";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../services";
import { THREE_D_STATUS_API } from "../services/constant";
import ThreedResultHistory from "./ThreedResultHistory";
import { langChange } from "../lang";
import { createPortal } from "react-dom";
import Loader from "../utils/Loader";

const Threed = () => {
  const navigate = useNavigate();
  const [threeDStatus, setThreeDStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem("b_m_a"));
  const fetchThreeDStatus = async () => {
    if (token) {
      setLoading(true);
      const res = await getMethod(THREE_D_STATUS_API, token.token);
      setLoading(false);
      res.status === 200 && setThreeDStatus(true);
    }
  };

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  useEffect(() => {
    fetchThreeDStatus();
  }, []);

  const threeDStatusHandler = () => {
    if (threeDStatus) {
      navigate("number-lists");
    } else {
      navigate("/closed");
    }
  };

  return (
    <>
      <Header />
      <div className="min-h-[70vh]">
        <div className="container mx-auto">
          <div className="p-3 mx-auto">
            <ul>
              <li className="shadow bg-[var(--primary-color)] p-3 rounded-lg text-[13px] flex items-center justify-between">
                <div
                  className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
                  onClick={() => navigate("history")}
                >
                  <img src={noteImg} className="w-[25px] mr-2" />
                  <p className="text-[13px] mb-0 mt-1 text-black">{langChange.history}</p>
                </div>
                <div
                  className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
                  onClick={() => navigate("winner-lists")}
                >
                  <img src={prizeImg} className="w-[25px]" />
                  <p className="text-[13px] mb-0 mt-1 text-black">{langChange.winner}</p>
                </div>
                <div
                  className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
                  onClick={threeDStatusHandler}
                >
                  <img src={numberImg} className="w-[25px]" />
                  <p className="text-[13px] mb-0 mt-1 text-black">{langChange.bet}</p>
                </div>
              </li>
            </ul>
            <div className="mt-6">
              <ThreedResultHistory />
            </div>
          </div>
        </div>
      </div>
      {
        createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  );
};

export default Threed;
