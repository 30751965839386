import React, { useState } from 'react'
import classes from '../assets/styles/Input.module.css';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const Input = ({icon,type,label,value,event,error, placeholder=""}) => {
  const [toggleIcon, setToggleIcon] = useState(false);
  if(type === 'password'){
      return (
        <div className={classes['input-group']}>
            <label htmlFor={label} className={classes['input-label']}>{label}</label>
            <div className={classes['input-container']}>
                <span className={classes['input-icon']}>
                    {icon}
                </span>
                <input type={toggleIcon ? "text" : "password"} value={value} onChange={event} className={`${classes['input-box']} text-sm py-3`} style={{paddingRight: '30px'}} placeholder={placeholder}/>
                <button className={classes['password-toggle-icon']} type='button' onClick={() => setToggleIcon(!toggleIcon)}>
                   {
                      toggleIcon? <FiEye />: <FiEyeOff />
                   }
                </button>
            </div>
            {
                error && <span className={classes['error-message']}>{error}</span>
            }
        </div>
      )
  }else{
      return (
        <div className={classes['input-group']}>
            <label htmlFor={label} className={classes['input-label']}>{label}</label>
            <div className={classes['input-container']}>
                <span className={classes['input-icon']}>
                    {icon}
                </span>
                <input type={type}  className={`${classes['input-box']} text-sm py-3`} value={value} onChange={event} style={{paddingRight: '8px'}} placeholder={placeholder}/>
            </div>
            {
                error && <span className={classes['error-message']}>{error}</span>
            }
        </div>
      )
  };
}

export default Input