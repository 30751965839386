// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader-container__LXY5b {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #00000087;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Loader_loader__Iuf6t {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--main-color);
  position: relative;
}
.Loader_loader__Iuf6t:before,
.Loader_loader__Iuf6t:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 0;
  background: #fff;
  transform: rotate(0deg) translate(30px);
  animation: Loader_rotate__-RRoT 1s ease infinite;
}
.Loader_loader__Iuf6t:after {
  animation-delay: 0.5s
}
@keyframes Loader_rotate__-RRoT {
  100% {transform: rotate(360deg) translate(30px)}
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,2BAA2B;IAC3B,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,6BAA6B;EAC7B,kBAAkB;AACpB;AACA;;EAEE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,gBAAgB;EAChB,uCAAuC;EACvC,gDAAkC;AACpC;AACA;EACE;AACF;AACA;EACE,MAAM,yCAAyC;AACjD","sourcesContent":[".loader-container {\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    background-color: #00000087;\n    z-index: 9999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.loader {\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background: var(--main-color);\n  position: relative;\n}\n.loader:before,\n.loader:after {\n  content: \"\";\n  position: absolute;\n  border-radius: 50%;\n  inset: 0;\n  background: #fff;\n  transform: rotate(0deg) translate(30px);\n  animation: rotate 1s ease infinite;\n}\n.loader:after {\n  animation-delay: 0.5s\n}\n@keyframes rotate {\n  100% {transform: rotate(360deg) translate(30px)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": `Loader_loader-container__LXY5b`,
	"loader": `Loader_loader__Iuf6t`,
	"rotate": `Loader_rotate__-RRoT`
};
export default ___CSS_LOADER_EXPORT___;
