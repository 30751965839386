import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Input from "../utils/Input";
import Select from "../utils/Select";
import Button from "../utils/Button";
import { FiKey, FiPhone, FiTablet, FiUserCheck, FiUserPlus } from "react-icons/fi";
import logo from "../assets/images/logo/logo.png";
import { Link, useNavigate } from "react-router-dom";
import classes from "../assets/styles/Login.module.css";
import { useDispatch, useSelector } from "react-redux";
import { postMethod } from "../services";
import { REGISTER_OTP_API } from "../services/constant";
import { userDataHandler } from "../store/slices/registerSlice";
import Loader from "../utils/Loader";
import { langChange } from "../lang";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccName, setBankAccName] = useState("");
  const [bankAccNo, setBankAccNo] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorPassword, setErrorPasword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [errorBankName, setErrorBankName] = useState("");
  const [errorBankAccName, setErrorBankAccName] = useState("");
  const [errorBankAccNo, setErrorBankAccNo] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const nameHandler = (e) => {
    setName(e.target.value);
    setErrorName("");
  };
  const phoneHandler = (e) => {
    setPhone(e.target.value);
    setErrorPhone("");
  };
  const bankNameHandler = (e) => {
    setBankName(e.target.value);
    setErrorBankName("");
  };
  const bankAccNameHandler = (e) => {
    setBankAccName(e.target.value);
    setErrorBankAccName("");
  };
  const bankAccNoHandler = (e) => {
    setBankAccNo(e.target.value);
    setErrorBankAccNo("");
  };
  const passwordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setPassword(e.target.value);
    setErrorPasword("");
  };
  const confirmPasswordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword("");
  };

  const makeRegister = async () => {
    const res = await postMethod(REGISTER_OTP_API, {
      phone,
    });
    res && setLoading(false);
    if (res?.con) {
      const data = {
        name,
        phone,
        bank_name: bankName,
        bank_acc_name: bankAccName,
        bank_acc_no: bankAccNo,
        password,
        confirm_password: confirmPassword,
      };
      dispatch(userDataHandler(data));
      navigate("/verify");
    } else {
      res?.data?.phone && setErrorPhone(res.data.phone[0]);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!name) {
      setErrorName("Name is required.");
      return;
    }
    if (!phone) {
      setErrorPhone("Phone is required.");
      return;
    }
    if (phone.length >= 9 && phone.length <= 20) {
      setErrorPhone("");
    } else {
      setErrorPhone("Phone number is minimum 9 and maximum 20.");
      return;
    }
    if (!bankName) {
      setErrorBankName("Bank name is required.");
      return;
    }
    if (!bankAccName) {
      setErrorBankAccName("Bank account name is required.");
      return;
    }
    if (!bankAccNo) {
      setErrorBankAccNo("Bank account number is required.");
      return;
    }
    if (bankAccNo.length >= 9 && bankAccNo.length <= 20) {
      setErrorBankAccNo("");
    } else {
      setErrorBankAccNo("Bank account number is minimum 9 and maximum 20.");
      return;
    }
    if (!password) {
      setErrorPasword("Password is required.");
      return;
    }
    if (password.length !== 6) {
      setErrorPasword("Password must be 6 digits.");
      return;
    }
    if (!confirmPassword) {
      setErrorConfirmPassword("Confirm password is required.");
      return;
    }
    makeRegister();
  };

  useEffect(() => {
    if (password === confirmPassword) {
      setErrorConfirmPassword("");
      setErrorPasword("");
    } else {
      setErrorConfirmPassword("Your password does not match");
    }
  }, [confirmPassword, password]);

  return (
    <>
      <div className="mx-auto h-[100vh] overflow-auto py-6">
        <div className="w-40 mx-auto mb-3">
          <Link to="/">
            <img src={logo} className="w-full" />
          </Link>
        </div>
        <div className="p-4">
          <form onSubmit={submitHandler}>
            <Input
              icon={<FiUserPlus />}
              label={langChange.account_name}
              placeholder={langChange.enter_account_name}
              type="text"
              value={name}
              event={nameHandler}
              error={errorName}
            />
            <Input
              icon={<FiPhone />}
              label={langChange.phone}
              type="number"
              value={phone}
              event={phoneHandler}
              error={errorPhone}
              placeholder={"09xxxxxxxxx"}
            />
            <Select
              icon={<FiTablet />}
              label={langChange.payment}
              event={(e) => bankNameHandler(e)}
              error={errorBankName}
            >
              <option value="#">{langChange.payment}</option>
              <option value="wave_pay">Wave Pay</option>
              <option value="kbz_pay">KBZ Pay</option>
              <option value="kbz_bank">KBZ Bank</option>
              <option value="cb_pay">CB Pay</option>
              <option value="cb_bank">CB Bank</option>
            </Select>
            <Input
              icon={<FiUserCheck />}
              label={langChange.bank_acc_name}
              type="text"
              value={bankAccName}
              event={bankAccNameHandler}
              error={errorBankAccName}
              placeholder={langChange.enter_bank_acc_name}
            />
            <Input
              icon={<FiPhone />}
              label={langChange.bank_acc_no_or_phone_no}
              type="number"
              value={bankAccNo}
              event={bankAccNoHandler}
              error={errorBankAccNo}
              placeholder={langChange.enter_bank_acc_no_or_phone_no}
            />
            <Input
              icon={<FiKey />}
              label={langChange.password}
              type="password"
              value={password}
              event={passwordHandler}
              error={errorPassword}
              placeholder={langChange.enter_password}
            />
            <Input
              icon={<FiKey />}
              label={langChange.confirm_password}
              type="password"
              value={confirmPassword}
              event={confirmPasswordHandler}
              error={errorConfirmPassword}
              placeholder={langChange.enter_confirm_password}
            />
            <div className="text-center">
              <Button label={langChange.confirm} type="submit" />
            </div>
          </form>
        </div>
        <div className={classes["forget-pass"]}>
          <Link to="/login">
            <p className={classes["forget-text"]}>
              {langChange.already_have_an_account}
            </p>
          </Link>
        </div>
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default Register;
