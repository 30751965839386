import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import OtpInput from 'react-otp-input';
import classes from '../assets/styles/RegisterVerify.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../assets/images/logo/logo.png';
import { postMethod } from '../services';
import { REGISTER_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import Loader from '../utils/Loader';
import { langChange } from '../lang';

const RegisterVerify = () => {
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState("");
  const [otp, setOtp] = useState('');
  const {registerVal} = useSelector(state => state.registerSlice);
  const navigate = useNavigate();

  useEffect(() => {
     if(!registerVal.name){
         navigate('/register');
     };
  },[registerVal]);

  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang])
  
  const submitHandler = async () => {
      setLoading(true);
      const data = {
         ...registerVal,
         verification_code: otp,
      }
      const res = await postMethod(REGISTER_API, data);
      if(res){
         setLoading(false);
         if(res === 'Failed to fetch'){
          SweetAlert.fire({
            width: '330px',
            icon: 'error',
            title: 'Connection!',
            text: 'Please check internet connection!',
            confirmButtonText: 'Ok',
          });
        };
        if(res?.con){
          SweetAlert.fire({
            width: '330px',
            icon: 'success',
            title: 'Success!',
            text: 'Register successful!',
            confirmButtonText: 'Home',
          }).then(result => {
              if(result.isConfirmed){
                  localStorage.setItem('b_m_a', JSON.stringify(res.data));
                  navigate('/');
              };
          });
        }else{
          if(res?.data?.error){
            setError(res?.data?.error);
          };
          if(res?.data?.name){
            setError(res?.data?.name[0]);
          };
          if(res?.data?.phone){
            setError(res?.data?.phone[0]);
          };
        };
      }
  };
  useEffect(()=>{
    if(otp.length === 6){
        submitHandler();
    }
    setError("");
  },[otp]);

  return (
    <>
        <div className='mx-auto h-screen py-6'>
          <div>
              <div className='w-40 mx-auto mb-3'>
                  <Link to="/">
                      <img src={logo} className='w-full'/>
                  </Link>
              </div>
          </div>
            <div className='p-4'>
                <div className={classes['auth-card-header']}>
                    {langChange.enter_otp_from_sms}
                </div>
                <form>
                    <div className='flex items-center justify-center'>
                      <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          inputType='number'
                          renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                          renderInput={(props) => <input {...props} className={classes['opt-input']} />}
                      />
                    </div>
                    {
                        error &&
                        <span className={classes['error-message']}>{error}</span>
                    }
                </form>
            </div>
        </div>
        {
           ReactDOM.createPortal(loading && <Loader/>, document.getElementById('portal'))
        }
    </>
  )
}

export default RegisterVerify