import React, { useState } from "react";
import { langChange } from "../lang";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function ThreedBetHistoryCard({ date, data }) {
  const [isDetail, setIsDetail] = useState(false);

  const calculateTotal = (data, type) => {
    return data?.reduce((a, b) => {
      return a + b[type];
    }, 0);
  };

  const maxDetailHeight = isDetail ? "500px" : "0";

  return (
    <div className="bg-blue-100 rounded-lg overflow-hidden mb-3">
      <div className="flex justify-between py-3 mx-3">
        <p className="text-slate-900">{date}</p>
        <p className="text-slate-900 flex">
          { data[0]?.lottery_time === 'One' ? `၁၆ ${langChange.lottery_date}` : `၁ ${langChange.lottery_date}` }
          {isDetail ? (
            <FiChevronUp
              className="ms-3 text-xl text-slate-900 cursor-pointer bg-slate-300 hover:bg-slate-400 rounded-full"
              onClick={() => setIsDetail(false)}
            />
          ) : (
            <FiChevronDown
              className="ms-3 text-xl text-slate-900 cursor-pointer bg-slate-300 hover:bg-slate-400 rounded-full"
              onClick={() => setIsDetail(true)}
            />
          )}
        </p>
      </div>
      <div
        className="details-section"
        style={{
          maxHeight: maxDetailHeight,
          overflow: "hidden",
          overflowY: "auto",
          transition: "max-height .5s ease-in-out",
        }}
      >
        <div className="border-t-[1px] border-t-indigo-500 mx-3 py-3 sticky top-0">
          <p className="text-[16px] text-blue-900 font-semibold">
            {langChange.total_bet_amount} - {`${calculateTotal(data, 'amount').toLocaleString('en-us')} ${langChange.kyat}`}
          </p>
        </div>
        <table className="w-full table">
          <thead className="bg-sky-100 sticky top-[50px] z-20">
            <tr>
              <th className="text-slate-900 py-2 border border-white border-t-0">
                {langChange.sr}
              </th>
              <th className="text-slate-900 py-2 border border-white border-t-0">
                {langChange.number}
              </th>
              <th className="text-slate-900 py-2 border border-white border-t-0">
                {langChange.amount}
              </th>
              <th className="text-slate-900 py-2 border border-white border-t-0">
                {langChange.status}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((history, i) => (
                <tr key={history.id}>
                  <td className="text-slate-600 py-2 border text-center">
                    {i + 1}
                  </td>
                  <td className="text-slate-600 py-2 border text-center">
                    {history.three_digit_number}
                  </td>
                  <td className="text-slate-600 py-2 border text-end pe-4">
                    {history.amount.toLocaleString('en-us')}
                  </td>
                  <td className="text-slate-600 py-2 border text-center">
                    {langChange.bet_approved}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
