import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import NotificationItem from "./NotificationItem";
import { MESSAGE_API } from "../services/constant";
import { getMethod } from "../services";
import Loader from "../utils/Loader";
import { langChange } from "../lang";
import NoData from "../utils/NoData";
import Layout from "../utils/Layout";
import Nav from "../layout/Nav";

export default function Notification() {
  const [messages, setMessages] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem("b_m_a"));
  const fetchMessage = async () => {
    setIsLoading(true);
    if (token) {
      const res = await getMethod(MESSAGE_API, token.token);
      res.con && setMessages(res.data);
      res && setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMessage();
  }, []);

  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className="h-[80vh] overflow-auto animate-fadein">
          <Layout>
            <div className="p-1">
              {messages.length > 0 ? (
                messages.map((message) => (
                  <NotificationItem
                    key={message?.id}
                    title={message.title}
                    body={message.body}
                  />
                ))
              ) : (
                <NoData />
              )}
            </div>
          </Layout>
        </div>
        <Nav />
      </div>
      {isLoading && <Loader />}
    </>
  );
}
