import React, { useEffect, useRef, useState } from "react";
import Header from "../../layout/Header";
import classes from "../../assets/styles/TwodHkwayHtoe.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { errorToaster } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { betNumbersHandler } from "../../store/slices/betNumbersSlice";
import { getMethod } from "../../services";
import {
  TWO_D_CLOSED_STATUS_API,
  TWO_D_LIMIT_API,
  TWO_D_REAL_TIME_API,
} from "../../services/constant";
import SweetAlert from "sweetalert2";
import moment from "moment-timezone";
import { langChange } from "../../lang";
import NoData from "../../utils/NoData";
import Loader from "../../utils/Loader";
import ReactDOM from 'react-dom';
import Nav from "../../layout/Nav";
import { IoIosArrowRoundBack } from "react-icons/io";
import { getWeekendLotteryType } from "./Wk2DHelper";
const WkTwodHkwayHtoe = () => {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const toastId = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const [closedNums, setClosedNums] = useState([]);
  const { value } = useSelector((state) => state.userInfoSlice);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [currentTime, setCurrentTime] = useState(
    moment().tz("Asia/Yangon").format("HH:mm:ss")
  );
  const [targetNum, setTargetNum] = useState("");
  const [resultNums, setResultNums] = useState([]);
  const [isApu, setIsApu] = useState("null");
  const [isLoading, setIsLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem("b_m_a"));

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const fetchTowDRealTime = async () => {
    if (token) {
      setIsLoading(true);
      const res = await getMethod(`${TWO_D_REAL_TIME_API}?type=Weekend`, token.token);
      res && setIsLoading(false);
      if (res.con) {
        if (res.data) {
          const time = getWeekendLotteryType(params.time);
          res.data.map((row) => {
            if (row.lottery_time === time) {
              setOpenTime(row.opening_time);
              setCloseTime(row.closing_time);
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    fetchTowDRealTime();
  }, []);

  const fetchTwoDLimit = async () => {
    const limitType = getWeekendLotteryType(params.time);

    if (!limitType) {
      navigate(-1);
      return;
    }

    if (!token) {
      navigate("/login");
      return;
    }

    setIsLoading(true);
    const res = await getMethod(
      `${TWO_D_LIMIT_API}?type=Weekend&time=${limitType}`,
      token.token
    );
    res && setIsLoading(false);

    if (!res) {
      return;
    }

    const tempLimitNums = res.data
      .filter((item) => !item.status || item.percentage >= 100)
      .map((item) => ({ number: item.number }));

      setIsLoading(true);
    const closeRes = await getMethod(`${TWO_D_CLOSED_STATUS_API}?type=Weekend`, token.token);
    closeRes && setIsLoading(false);
    closeRes.con && tempLimitNums.push(...closeRes.data);
    setClosedNums(tempLimitNums);
  };

  useEffect(() => {
    if (currentTime && closeTime && openTime) {
      if (currentTime > openTime && currentTime < closeTime) {
        fetchTwoDLimit();
      } else {
        SweetAlert.fire({
          width: "330px",
          icon: "warning",
          title: "Sorry!",
          text: `သတ်မှတ်ထီထိုးချိန် ကျော်လွန်သွားပါပြီ။`,
          confirmButtonText: "Ok",
        }).then(() => {
          navigate(-2);
        });
      }
    }
  }, [currentTime, openTime, closeTime]);

  const submitHandler = () => {
    const totalAmount = resultNums.length * amount;
        if (value?.user_info?.current_balance >= totalAmount) {
          if (amount >= 100) {
            if (resultNums.length > 0) {
              dispatch(betNumbersHandler(resultNums));
              navigate(`/all/bet/weekend2d/time/${params.time}/confirm`);
            }
          } else {
            errorToaster("Minium bet amount is 100", toastId);
          }
        } else {
          errorToaster("Balance insufficient to bet!", toastId);
        }
  };

  const targetNumHandler = (e) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    // positiveNumberPattern.test(value) && setTargetNum(value.slice(0, 4));
    positiveNumberPattern.test(value) && setTargetNum(value.slice(0, 7));
  };

  const amountHandler = (e) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(value) && setAmount(value);
  };

  const generateNumber = (apu) => {
    if (amount) {
      if (amount >= 100) {
        if (targetNum) {
          if (targetNum.length >= 3) {
            const results = [];
            const closeAlert = [];
            const isUnique =
              new Set(targetNum.split("")).size === targetNum.length;
            if (isUnique) {
              for (let i = 0; i < targetNum.length; i++) {
                for (let j = 0; j < targetNum.length; j++) {
                  const combination = targetNum[i] + targetNum[j];
                  const find = closedNums.find(
                    (num) => num.number === combination
                  );
                  if (apu) {
                    setIsApu("apu");
                    if (find) {
                      closeAlert.push(find.number);
                    } else {
                      results.push({
                        id: +combination,
                        number: combination,
                        amount,
                        time: params.time,
                      });
                    }
                  } else {
                    setIsApu("no_apu");
                    if (i !== j) {
                      if (find) {
                        closeAlert.push(find.number);
                      } else {
                        results.push({
                           id: +combination,
                           number: combination,
                           amount,
                           time: params.time,
                         });
                      }
                    }
                  }
                }
              }
              const totalBetAmount = results?.length > 0 ? results.reduce((a,b) => a + +b.amount, 0) : 0;
              if (value?.user_info?.current_balance < totalBetAmount) {
                errorToaster('Balance insufficient to bet!', toastId)
                setIsApu("null");
                return;
              }
              closeAlert.length > 0 &&  SweetAlert.fire({
               width: '330px',
               icon: 'warning',
               title: 'Sorry!',
               text: `( ${[...closeAlert]} ) ${closeAlert.length > 1 ? 'are' : 'is'} not avaliable!`,
               confirmButtonText: 'Ok',
            })
            } else {
              errorToaster("အပူး (သို့) ဂဏန်းတူ (၂)လုံး ထည့်၍ မရပါ", toastId);
            }
            setResultNums(results);
          } else {
            errorToaster(
              "အနည်းဆုံး (၃) လုံးမှ အများဆုံး (၄) လုံးအတွင်း ဖြစ်ရမည်။",
              toastId
            );
          }
        } else {
          errorToaster("ခွေထိုးမည့် ဂဏန်းထည့်ပါ", toastId);
        }
      } else {
        errorToaster("အနည်းဆုံး ၁၀၀ ကျပ်", toastId);
      }
    } else {
      errorToaster("ငွေပမာဏ ထည့်ပါ", toastId);
    }
  };

  const apuHandler = () => {
    if (isApu !== "apu") {
      generateNumber(true);
    } else {
      setIsApu("null");
      setResultNums([]);
    }
  };
  const noApuHandler = () => {
    if (isApu !== "no_apu") {
      generateNumber(false);
    } else {
      setIsApu("null");
      setResultNums([]);
    }
  };
  const backHandler = () => navigate(-1);
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className='px-3 mt-3'>
            <button className='bg-[var(--main-color)] p-1 rounded-full' onClick={backHandler}>
              <IoIosArrowRoundBack size={'30px'}/>
            </button>
        </div>
        <div className="p-3">
          <div className="container mx-auto">
            <div className="">
              <div className="sticky lg:top-[78px] top-[56px] z-10 mb-3">
                <div className="flex flex-col md:flex-row gap-2">
                  <div className="flex gap-2 w-[100%] md:w-[70%]">
                    <input
                      className={`text-slate-500 w-[50%] p-2 rounded-md border border-[var(--accent-color)] bg-[var(--primary-color)] ${
                        isApu !== "null" ? "cursor-not-allowed" : ""
                      }`}
                      type="text"
                      value={targetNum}
                      onChange={targetNumHandler}
                      disabled={isApu !== "null"}
                      placeholder={langChange.min_max_number}
                    />
                    <input
                      className={`text-slate-500 w-[50%] p-2 rounded-md border border-[var(--accent-color)] bg-[var(--primary-color)] text-end ${
                        isApu !== "null" ? "cursor-not-allowed" : ""
                      }`}
                      type="text"
                      placeholder={langChange.min_100}
                      value={amount}
                      disabled={isApu !== "null"}
                      onChange={amountHandler}
                    />
                  </div>
                  <div className="flex gap-2 w-[100%] md:w-[30%]">
                    <button
                      className={`w-[50%] rounded-lg py-2 px-3 whitespace-nowrap ${
                        isApu === "no_apu"
                          ? "cursor-not-allowed hover:border-[var(--accent-color)"
                          : "cursor-pointer hover:border-slate-200"
                      } ${
                        isApu === "apu"
                          ? "bg-red-600"
                          : "bg-[var(--main-color)] text-[var(--text-color)]"
                      }`}
                      onClick={apuHandler}
                      disabled={isApu === "no_apu"}
                    >
                      {isApu === "apu"
                        ? langChange.remove
                        : langChange.apu_number}
                    </button>
                    <button
                      className={`w-[50%] bg-[var(--accent-color)] rounded-lg py-2 px-3 whitespace-nowrap ${
                        isApu === "apu"
                          ? "cursor-not-allowed hover:border-[var(--accent-color)]"
                          : "cursor-pointer hover:border-slate-200"
                      } ${
                        isApu === "no_apu"
                          ? "bg-red-600"
                          : "bg-[var(--main-color)] text-[var(--text-color)]"
                      }`}
                      onClick={noApuHandler}
                      disabled={isApu === "apu"}
                    >
                      {isApu === "no_apu"
                        ? langChange.remove
                        : langChange.no_apu_number}
                    </button>
                  </div>
                </div>
              </div>
              <div className={classes["table-card-container"]}>
                <ul className={classes["table-header"]}>
                  <li>{langChange.sr}</li>
                  <li>{langChange.number}</li>
                  <li>{langChange.amount}</li>
                </ul>
                {resultNums.length > 0 ? (
                  resultNums.map((num, i) => (
                    <ul key={num.number} className={classes["table-header"]}>
                      <li className="text-black">{i + 1}.</li>
                      <li className="text-[var(--main-color)]">{num.number}</li>
                      <li className="text-black">{num.amount?.toLocaleString("en-us")}</li>
                    </ul>
                  ))
                ) : (
                  <NoData />
                )}
                {resultNums.length > 0 && (
                  <button className="w-full border border-[var(--main-color)] bg-[var(--main-color)] transition-[1s] rounded-lg py-[10px] px-3 cursor-pointer whitespace-nowrap sticky bottom-0 text-[var(--textP-color)]" onClick={submitHandler}>
                    {langChange.bet}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <Nav />
      </div>
      {
        ReactDOM.createPortal(isLoading && <Loader />, document.getElementById("portal"))
      }
      <ToastContainer limit={1} />
    </>
  );
};

export default WkTwodHkwayHtoe;
