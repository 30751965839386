import React, { useEffect } from "react";
import Header from "../../layout/Header";
import classes from "../../assets/styles/ThreedHistory.module.css";
import { useNavigate } from "react-router-dom";
import noteImg from "../../assets/images/icons/note.png";
import calendarImg from "../../assets/images/icons/calendar.png";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import Nav from "../../layout/Nav";
import WkThreedBetHistory from "./WkThreedBetHistory";
import WkThreedResultHistory from "./WkThreedResultHistory";

const WkThreedHistory = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className="h-[80vh] overflow-auto">
          <div className="container mx-auto">
            <div className="p-3 mx-auto">
              <div className={classes["history-card-header"]}>
                <span
                  className={`text-black ${
                    (url === "/all/bet/weekend3d/history" ||
                      url === "/2d3d/bet/weekend3d/history") &&
                    classes.active
                  }`}
                  onClick={() => navigate("/all/bet/weekend3d/history")}
                >
                  <img src={noteImg} className="w-[25px] mr-2" />
                  <p className="text-[13px] mb-0 mt-1">{langChange.history}</p>
                </span>
                <span
                  className={`text-black ${
                    (url === "/all/bet/weekend3d/result-history" ||
                      url === "/2d3d/bet/weekend3d/result-history") &&
                    classes.active
                  }`}
                  onClick={() => navigate("/all/bet/weekend3d/result-history")}
                >
                  <img src={calendarImg} className="w-[25px] mr-2" />
                  <p className="text-[13px] mb-0 mt-1">
                    {langChange.lottery_history}
                  </p>
                </span>
              </div>
              {url === "/all/bet/weekend3d/history" ? (
                <WkThreedBetHistory />
              ) : (
                <WkThreedResultHistory />
              )}
            </div>
          </div>
        </div>
        <Nav />
      </div>
    </>
  );
};

export default WkThreedHistory;
