import { configureStore } from '@reduxjs/toolkit';
import registerSlice from './slices/registerSlice'
import depositSlice from './slices/depositSlice';
import userInfoSlice from './slices/userInfoSlice';
import betNumbersSlice from './slices/betNumbersSlice';
import pocketStatusSlice from './slices/pocketStatusSlice';
import langSlice from './slices/langSlice';

export const store = configureStore({
  reducer: {
    registerSlice: registerSlice,
    depositStoreSlice: depositSlice,
    userInfoSlice,
    betNumbersSlice,
    pocketStatusSlice,
    lang: langSlice,
  },
})