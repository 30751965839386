import React, { useEffect } from "react";
import Header from "../layout/Header";
import classes from "../assets/styles/TwodHistory.module.css";
import { useNavigate } from "react-router-dom";
import noteImg from "../assets/images/icons/note.png";
import calendarImg from "../assets/images/icons/calendar.png";
import TwodResultHistory from "./TwodResultHistory";
import TwodBetHistry from "./TwodBetHistry";
import { langChange } from "../lang";
import { useSelector } from "react-redux";
import Nav from "../layout/Nav";
import {IoIosArrowRoundBack} from 'react-icons/io';

const TwodHistory = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const backHandler = () => navigate(-1);

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className='px-3 mt-3'>
          <button className='bg-[var(--main-color)] p-1 rounded-full' onClick={backHandler}>
              <IoIosArrowRoundBack size={'30px'}/>
          </button>
        </div>
        <div className="min-h-[70vh]">
          <div className="container mx-auto">
            <div className="p-3 pt-0">
              <div className={classes["history-card-header"]}>
                <span
                  className={`${
                    (url === "/all/bet/2d/history" ||
                      url === "/2d3d/bet/2d/history") &&
                    classes.active
                  }`}
                  onClick={() => navigate("/all/bet/2d/history")}
                >
                  <img src={noteImg} className="w-[25px] mr-2" />
                  <p className="text-[13px] mb-0 mt-1 text-black">{langChange.history}</p>
                </span>
                <span
                  className={`${
                    (url === "/all/bet/2d/result-history" ||
                      url === "/2d3d/bet/2d/result-history") &&
                    classes.active
                  }`}
                  onClick={() => navigate("/all/bet/2d/result-history")}
                >
                  <img src={calendarImg} className="w-[25px] mr-2" />
                  <p className="text-[13px] mb-0 mt-1 text-black">
                    {langChange.lottery_history}
                  </p>
                </span>
              </div>
              {url === "/all/bet/2d/history" ? (
                <TwodBetHistry />
              ) : (
                <TwodResultHistory />
              )}
            </div>
          </div>
        </div>
        <Nav />
      </div>
    </>
  );
};

export default TwodHistory;
