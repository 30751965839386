import React, { useEffect } from 'react'
import closeImg from '../assets/images/close.png'
import Header from '../layout/Header'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Nav from '../layout/Nav'
import { IoIosArrowRoundBack } from 'react-icons/io'

export default function Closed() {
    const navigate = useNavigate();
    const { lang } = useSelector(state => state.lang);
    useEffect(() => {}, [lang]);
    const backHandler = () => navigate(-1);
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className='px-3 mt-3'>
            <button className='bg-[var(--main-color)] p-1 rounded-full' onClick={backHandler}>
              <IoIosArrowRoundBack size={'30px'}/>
            </button>
        </div>
        <div className='flex justify-center flex-col min-h-[70vh]'>
            <img className='w-[320px] mx-auto' src={closeImg} alt="Close.png" />
        </div>
        <Nav />
      </div>
    </>
  )
}
