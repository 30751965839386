const domain = process.env.REACT_APP_DOMAIN;
const provider_domain = process.env.REACT_APP_PROVIDER_DOMAIN;
// const domain = 'http://192.168.89.119:8080/';

export const REGISTER_API = `${domain}api/register`;
export const GAME_REGISTER_API = `${domain}api/game-register`;
export const REGISTER_OTP_API = `${domain}api/create-login`;
export const NAME_CHECK_API = `${domain}api/check-name`;
export const PASSWORD_CREATE_OTP_API = `${domain}api/password/create`;
export const PASSWORD_RESET_API = `${domain}api/password/reset`;
export const LOGIN_API = `${domain}api/login`;
export const CHANGE_PASSWORD_API = `${domain}api/change-password`;
export const USER_INFO_API = `${domain}api/user-info`;
export const PROVIDER_CONNECT_API = `${provider_domain}api/provider-connect-lists`;
export const PROVIDER_API = `${provider_domain}api/providers`;
export const GAMES_LIST_API = `${provider_domain}api/game-lists`;
export const GAME_LAUNCH_API = `${provider_domain}api/slot/init`;
export const PAYMENT_PROVIDER_API = `${domain}api/providers`;
export const USER_DEPOSIT_API = `${domain}api/deposits`;
export const DEPOSIT_HISTORY_API = `${domain}api/deposits`;
export const USER_WITHDRAW_API = `${domain}api/withdrawals`;
export const WITHDRAW_HISTORY_API = `${domain}api/withdrawals`;
export const BANNER_API = `${domain}api/advertise`;
export const CAPTION_API = `${domain}api/caption`;
export const POCKET_STATUS_API = `${domain}api/app-system`;
export const CONTACT_API = `${domain}api/contact`;
export const TRANSACTION_BETWEEN_API = `${domain}api/transaction-between-787`;
export const MESSAGE_API = `${domain}api/messages`;
export const FEEDBACK_API = `${domain}api/feedback`;
export const FEEDBACK_PHOTO_API = `${domain}api/file-upload`;
export const FEEDBACK_PHOTO_REMOVE_API = `${domain}api/file-remove`;
export const PROMOTION_API = `${domain}api/promotions`;
export const CHANGE_NAME_API = `${domain}api/change-name`;
export const CHANGE_PHOTO_API = `${domain}api/change-photo`;


// for Burma 2D
export const BURMA_TWO_D_STATUS_API = `${domain}api/close-burma2d`;
export const TWO_D_BET_BURMA_API = `${domain}api/bets-burma`;

// for Weekend 2D 
export const WK_2D_STATUS_API = `${domain}api/close-weekend2d`;
export const WK_2D_BET_API = `${domain}api/bets-weekend-two`;

// for 2D 
export const TWO_D_BET_LIST_API = `${domain}api/bets`;
export const TWO_D_STATUS_API = `${domain}api/close-mm2d`;
export const TWO_D_CLOSED_STATUS_API = `${domain}api/two-digits/limit-myanmar`;
export const TWO_D_LIMIT_API = `${domain}api/two-digits/limit`;
export const TWO_D_BET_THAI_API = `${domain}api/bets-thai`;
export const TWO_D_WINNER_API = `${domain}api/two-winner-records`;
export const TWO_D_RESULT_API = `${domain}api/2d-results`;
export const TWO_D_REAL_TIME_API = `${domain}api/two-lottery-close`;
// for 3D
export const THREE_D_STATUS_API = `${domain}api/close_three`;
export const THREE_D_BET_API = `${domain}api/three_lucky_draws`;
export const THREE_D_LOTTERY_CLOSE_API = `${domain}api/three-lottery-close`

// for Wk3d 
export const WK_3D_CLOSE_API = `${domain}api/close-weekend3d`;
export const WK_3D_BET_API = `${domain}api/bets-weekend-three`;

export const THREE_D_LUCKY_NUMS_API = `${domain}api/three_lucky_numbers`;
export const THREE_D_WINNER_API = `${domain}api/three-winner-records`;
export const THREE_D_LIMIT_API = `${domain}api/three-digits/limit`;
export const THREE_D_CLOSED_STATUS_API = `${domain}api/three-digits/limit-myanmar`;