import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DepositHistory from './DepositHistory';
import Header from '../layout/Header';
import classes from '../assets/styles/Deposit.module.css';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
import WithdrawHistory from './WithdrawHistory';
import Layout from '../utils/Layout';
import Nav from '../layout/Nav';

const TransactionHistory = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const { lang } = useSelector(state => state.lang)
  useEffect(()=> {}, [lang])
  return (
    <>
    <div className='pb-[100px] relative min-h-screen'>
      <Header />
      <div className='p-3 pt-0'>
        <Layout>
            <div className='max-w-[460px] mx-auto'>
              <div className={`${classes['auth-card-header']} mb-3`}>
                  <span className={`text-black ${url === '/deposit-transaction' && classes.active}`} onClick={()=> navigate('/deposit-transaction')}>{langChange.deposit_history}</span>
                  <span className={`text-black ${url === '/withdraw-transaction' && classes.active}`} onClick={()=> navigate('/withdraw-transaction')}>{langChange.withdraw_history}</span>
              </div>
              {
                url === '/deposit-transaction'?
                <DepositHistory />:
                <WithdrawHistory />
              } 
            </div>
        </Layout>
      </div>
      <Nav />
    </div>
    </>
  )
}

export default TransactionHistory
