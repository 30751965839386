import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import classes from "../assets/styles/ForgotPassword.module.css";
import Button from "../utils/Button";
import { Link, useNavigate } from "react-router-dom";
import Input from "../utils/Input";
import logo from "../assets/images/logo/logo.png";
import {
  cleanUserDataHandler,
  userDataHandler,
} from "../store/slices/registerSlice";
import { useDispatch, useSelector } from "react-redux";
import { postMethod } from "../services";
import { PASSWORD_CREATE_OTP_API } from "../services/constant";
import SweetAlert from "sweetalert2";
import { FiPhone } from "react-icons/fi";
import { langChange } from "../lang";
import Loader from "../utils/Loader";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const phoneHandler = (e) => {
    setPhone(e.target.value);
    setErrorPhone("");
  };
  const forgetSubmit = async (e) => {
    e.preventDefault();
    if (!phone) {
      setErrorPhone("Phone is required.");
      return;
    }
    if (phone.length >= 9 && phone.length <= 20) {
      setErrorPhone("");
    } else {
      setErrorPhone("Phone number must be minimum 9 and maximum 20.");
      return;
    }

    setLoading(true);
    const res = await postMethod(PASSWORD_CREATE_OTP_API, {
      phone,
    });
    res && setLoading(false);
    if (res === "Failed to fetch") {
      SweetAlert.fire({
        width: "330px",
        icon: "error",
        title: "Connection!",
        text: "Please check internet connection!",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (res.con) {
      dispatch(userDataHandler({ phone }));
      navigate("/verify/password");
      return;
    } else {
      res?.data?.phone && setErrorPhone(res.data.phone[0]);
      return;
    }
  };

  useEffect(() => {
    dispatch(cleanUserDataHandler());
  }, []);
  return (
    <div className="mx-auto h-screen py-6">
      <div>
        <div className="w-40 mx-auto mb-3">
          <Link to="/">
            <img src={logo} className="w-full" />
          </Link>
        </div>
      </div>
      <div className="p-4">
        <form onSubmit={forgetSubmit}>
          <Input
            icon={<FiPhone />}
            label={langChange.phone}
            type="number"
            value={phone}
            event={phoneHandler}
            error={errorPhone}
            placeholder={"09xxxxxxxxx"}
          />
          <div className="text-center">
            <Button label={langChange.get_otp_sms} type="submit" />
          </div>
        </form>
      </div>
      <div className={classes["forget-pass"]}>
        <Link to="/login">
          <p className={classes["forget-text"]}>
            {langChange.already_have_an_account}
          </p>
        </Link>
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </div>
  );
};

export default ForgotPassword;
