import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import { CONTACT_API } from "../services/constant";
import { getMethod } from "../services";
import Loader from "../utils/Loader";
import { langChange } from "../lang";
import Layout from "../utils/Layout";
import Nav from "../layout/Nav";

export default function RuleAndRegulation() {
  const [messages, setMessages] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchMessage = async () => {
    setIsLoading(true);
    const res = await getMethod(CONTACT_API);
    res.con && setMessages(res.data);
    res && setIsLoading(false);
  };

  useEffect(() => {
    fetchMessage();
  }, []);

  return (
    <div className='pb-[100px] relative min-h-screen'>
      <Header />
      <div className="h-[80vh] overflow-y-auto">
        <Layout type={langChange.rule_and_regulation}>
          <div className="w-[95%] md:w-[500px] mx-auto mt-5 mb-10">
            <div className="leading-8 max-w-[500px] mx-auto text-center text-black" dangerouslySetInnerHTML={{__html: messages.rule}} />
          </div>
        </Layout>
      </div>
      <Nav />
      {isLoading && <Loader />}
    </div>
  );
}
