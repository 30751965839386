import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import { useSelector } from "react-redux";
import noteImg from "../../assets/images/icons/history.png";
import prizeImg from "../../assets/images/icons/winner.png";
import numberImg from "../../assets/images/icons/number-2.png";
import { Link, useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import {
  TWO_D_REAL_TIME_API,
  WK_2D_STATUS_API,
} from "../../services/constant";
import moment from "moment-timezone";
import { langChange } from "../../lang";
import Nav from "../../layout/Nav";
import { IoIosArrowRoundBack } from "react-icons/io";
import TimeModel from "../../utils/TimeModel";
import { FiClock } from "react-icons/fi";
import { getWeekendLotteryTime } from "./Wk2DHelper";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";
import Wk2DLive from "./Wk2DLive";

const Wk2D = () => {
  const [timeShow, setTimeShow] = useState(false);
  const navigate = useNavigate();
  const [lotteries, setLotteries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lLoading, setLloaidng] = useState(false);
  const [towDStatus, setTowDStatus] = useState(false);
  const [currentTime, setCurrentTime] = useState("");

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const token = JSON.parse(localStorage.getItem("b_m_a"));
  const fetchtowDStatus = async () => {
    if (token) {
      setIsLoading(true);
      const res = await getMethod(WK_2D_STATUS_API, token.token);
      setIsLoading(false);
      res.status === 200 && setTowDStatus(true);
    }
  };

  useEffect(() => {
    fetchtowDStatus();
  }, []);

  const fetchTowDRealTime = async () => {
    if (token) {
      setLloaidng(true);
      const res = await getMethod(
        `${TWO_D_REAL_TIME_API}?type=Weekend`,
        token.token
      );
      setLloaidng(false);
      if (res.con) {
        setLotteries(res?.data);
      }
    }
  };

  const towDStatusHandler = () => {
    fetchTowDRealTime();
    setCurrentTime(moment().tz("Asia/Yangon").format("HH:mm:ss"));
    if (towDStatus) {
      setTimeShow(true);
    } else {
      navigate("/closed");
    }
  };

  const backHandler = () => navigate(-1);

  return (
    <>
      <div className="pb-[100px] relative min-h-screen">
        <Header />
        <div className="px-3 mt-3">
          <button
            className="bg-[var(--main-color)] p-1 rounded-full"
            onClick={backHandler}
          >
            <IoIosArrowRoundBack size={"30px"} />
          </button>
        </div>
        <div className="h-[100vh] overflow-auto">
          <div className="container mx-auto">
            <div className="p-3">
              <ul>
                <li className="shadow bg-[var(--primary-color)] p-3 rounded-lg text-[13px] flex items-center justify-between">
                  <div
                    className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
                    onClick={() => navigate("history")}
                  >
                    <img src={noteImg} className="w-[25px] mr-2" />
                    <p className="text-[13px] mb-0 mt-1 text-black">
                      {langChange.history}
                    </p>
                  </div>
                  <div
                    className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
                    onClick={() => navigate("winner-lists")}
                  >
                    <img src={prizeImg} className="w-[25px]" />
                    <p className="text-[13px] mb-0 mt-1 text-black">
                      {langChange.winner}
                    </p>
                  </div>
                  <div
                    className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
                    onClick={towDStatusHandler}
                  >
                    <img src={numberImg} className="w-[25px]" />
                    <p className="text-[13px] mb-0 mt-1 text-black">
                      {langChange.bet}
                    </p>
                  </div>
                </li>
              </ul>
              <div className="mt-3"></div>
              <Wk2DLive route="iframe" />
            </div>
          </div>
        </div>
        <Nav />
        <TimeModel isShow={timeShow} setIsShow={setTimeShow}>
          <h3 className="mb-5 flex items-center justify-center text-[13px] font-bold">
            <FiClock className="mr-2 text-xl text-[#eb5c00]" />{" "}
            <span className="text-black">{langChange.select_lottery_time}</span>
          </h3>
          <ul>
            {lotteries.map(
              (lottery, i) =>
                currentTime >= lottery?.opening_time &&
                currentTime <= lottery?.closing_time && (
                  <li key={`weekend_key_${i}_${lottery?.opening_time}`}>
                    <Link
                      to={`time/${getWeekendLotteryTime(lottery.lottery_time)}`}
                    >
                      <div className="text-center text-[13px] block bg-[var(--primary-color)] border-[var(--light-color)] border p-1.5 text-xl mb-3 rounded-lg text-red-600 cursor-pointer">
                        {getWeekendLotteryTime(lottery.lottery_time)}
                      </div>
                    </Link>
                  </li>
                )
            )}
          </ul>
        </TimeModel>
        {createPortal(
          (isLoading || lLoading) && <Loader />,
          document.getElementById("portal")
        )}
      </div>
    </>
  );
};

export default Wk2D;
