import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import { FiPocket, FiRepeat } from 'react-icons/fi';
import { BiSolidRightArrow } from 'react-icons/bi';
import { FaGamepad, FaRegPlayCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import depositImg from '../assets/images/icons/Transfer.png';
import withdrawImg from '../assets/images/icons/Money Out.png';
import footballImg from '../assets/images/icons/football.png';
import slotImg from '../assets/images/icons/slot.png';
import liveCasinoImg from '../assets/images/icons/livecasino.png';
import DetailModel from '../utils/DetailModel';
import { useDispatch, useSelector } from 'react-redux';
import Marquee from 'react-fast-marquee';
import { getMethod, postMethod } from '../services';
import { POCKET_STATUS_API, TRANSACTION_BETWEEN_API } from '../services/constant';
import { pocketStatusDataHandler } from '../store/slices/pocketStatusSlice';
import SweetAlert from 'sweetalert2';
import PopupFrame from './PopupFrame';
import { langChange } from '../lang';
import { infoHandler } from '../store/slices/userInfoSlice';
import Nav from '../layout/Nav';

const Pocket = () => {
  const [pocketShow, setPocketShow] = useState(false);
  const [gamePocketShow, setGamePocketShow] = useState(false);
  const { value } = useSelector(state => state.userInfoSlice);
  const [toGameAmount, setToGameAmount] = useState("");
  const [errorGameAmount, setErrorToGameAmount] = useState("");
  const [transferToGameShow, setTransferToGameShow] = useState(false);
  const [toPocketAmount, setToPocketAmount] = useState("");
  const [errorPocketAmount, setErrorToPocketAmount] = useState("");
  const [transferToPocketShow, setTransferToPocketShow] = useState(false);
  const [pocketStatusData, setPocketStatusData] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  const toPocketAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setToPocketAmount(amount);
    setErrorToPocketAmount("");
  }
  const transferToGameShowHandler = () => {
    if (value?.user_info?.current_balance > 0) {
      setToGameAmount(value?.user_info?.current_balance)
      setTransferToGameShow(true)
    } else {
      SweetAlert.fire({
        width: '330px',
        icon: 'error',
        title: 'Sorry!',
        text: 'Balance insufficient to transfer!',
        confirmButtonText: 'Ok',
      });
    }
  }
  const transferToPocketShowHandler = () => {
    if (value?.game_info?.balance > 0) {
      setToPocketAmount(value?.game_info?.balance)
      setTransferToPocketShow(true)
    } else {
      SweetAlert.fire({
        width: '330px',
        icon: 'error',
        title: 'Sorry!',
        text: 'Balance insufficient to transfer!',
        confirmButtonText: 'Ok',
      });
    }
  }
  const toGameAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setToGameAmount(amount);
    setErrorToGameAmount("");
  }
  const submitToGameTransfer = async () => {
    const token = JSON.parse(localStorage.getItem('b_m_a'));
    if (token) {
      if (toGameAmount) {
        if (Number(toGameAmount) <= Number(value?.user_info?.current_balance)) {
          setLoading(true)
          const data = {
            amount: toGameAmount,
            status: 'main_to_787game',
            game_token: token.game_token,
          }
          const res = await postMethod(TRANSACTION_BETWEEN_API, data, token.token)
          if (res) {
            setLoading(false);
            setTransferToGameShow(false)
            if (res === 'Failed to fetch') {
              SweetAlert.fire({
                width: '330px',
                icon: 'error',
                title: 'Connection!',
                text: 'Please check internet connection!',
                confirmButtonText: 'Ok',
              });
            };
            if (res.con) {
              setToGameAmount("")
              dispatch(infoHandler({}))
              setErrorToGameAmount("")
              SweetAlert.fire({
                width: '330px',
                icon: 'success',
                title: 'Success!',
                text: 'Transfer Successful!',
                confirmButtonText: 'Ok',
              }).then(result => {
                if (result.isConfirmed) {
                  navigate('/');
                }
              });
            } else if (res?.data.amount) {
              SweetAlert.fire({
                width: '330px',
                icon: 'error',
                title: 'Sorry!',
                text: res.data.amount[0],
                confirmButtonText: 'Ok',
              });
            }
          }
        } else {
          setErrorToGameAmount('User balance insufficient to transfer!')
        }
      } else {
        setErrorToGameAmount('Transfer amount is required!')
      }
    } else {
      setLoading(false);
      setTransferToGameShow(false)
      navigate('/login');
    }
  }
  const submitToPocketTransfer = async () => {
    const token = JSON.parse(localStorage.getItem('b_m_a'));
    if (token) {
      if (toPocketAmount) {
        if (Number(toPocketAmount) <= Number(value?.game_info?.balance)) {
          setLoading(true)
          const data = {
            amount: toPocketAmount,
            status: '787game_to_main',
            game_token: token.game_token,
          }
          const res = await postMethod(TRANSACTION_BETWEEN_API, data, token.token)
          if (res) {
            setLoading(false);
            setTransferToPocketShow(false)
            if (res === 'Failed to fetch') {
              SweetAlert.fire({
                width: '330px',
                icon: 'error',
                title: 'Connection!',
                text: 'Please check internet connection!',
                confirmButtonText: 'Ok',
              });
            };
            if (res.con) {
              setToPocketAmount("")
              setErrorToPocketAmount("")
              dispatch(infoHandler({}))
              SweetAlert.fire({
                width: '330px',
                icon: 'success',
                title: 'Success!',
                text: 'Transfer Successful!',
                confirmButtonText: 'Ok',
              }).then(result => {
                if (result.isConfirmed) {
                  navigate('/');
                }
              });
            } else if (res?.data.amount) {
              SweetAlert.fire({
                width: '330px',
                icon: 'error',
                title: 'Sorry!',
                text: res.data.amount[0],
                confirmButtonText: 'Ok',
              });
            }
          }
        } else {
          setErrorToPocketAmount('Game balance insufficient to transfer!')
        }
      } else {
        setErrorToPocketAmount('Transfer amount is required!')
      }
    } else {
      setLoading(false);
      setTransferToPocketShow(false)
      navigate('/login');
    }
  }
  // deposit and withdraw status handler 
  const token = JSON.parse(localStorage.getItem('b_m_a'));
  const fetchPocketStatus = async (route) => {
    if (token) {
      const res = await getMethod(`${POCKET_STATUS_API}`, token.token);
      if (res.con) {
        dispatch(pocketStatusDataHandler(res.data));
        if (route === 'Deposit') {
          res.data.deposit_status ? navigate("/deposit") : SweetAlert.fire({
            width: '330px',
            icon: 'error',
            title: 'Sorry!',
            text: `Deposit is temporary closed!`,
          });
        }
        if (route === 'Withdraw') {
          res.data.withdrawal_status ? navigate("/withdraw") : SweetAlert.fire({
            width: '330px',
            icon: 'error',
            title: 'Sorry!',
            text: `Withdraw is temporary closed!`,
          });
        }
        return setPocketStatusData(res.data);
      }
    } else {
      console.log('Unauthorized User!')
    }
  }
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className='h-[80vh] overflow-y-auto'>
          <div className='container mx-auto p-3'>
            <ul className='shadow bg-[var(--primary-color)] rounded-lg p-1'>
              <li className=' p-3 rounded-lg text-[13px] font-light mb-3 flex items-center justify-between'>
                <div className='flex items-center'>
                  <FiPocket className='text-2xl text-[var(--text-color)]' />
                  <span className='ml-2 text-black'>{langChange.main_pocket}</span>
                  <span className='ml-2 block text-slate-400 border-slate-400 w-[23px] h-[23px] border text-[13px] text-center leading-[23px] rounded-full cursor-pointer' onClick={() => setPocketShow(true)}>&#63;</span>
                </div>
                <div className='flex items-center'>
                  <span className='text-black'>{value?.user_info?.current_balance?.toLocaleString('en-us') ?? 0} {langChange.kyat}</span>
                  <span className='ml-2 block w-[23px] h-[23px] border border-[var(--main-color)] text-[13px] text-center leading-[23px] rounded-full cursor-pointer text-[var(--main-color)]' onClick={transferToPocketShowHandler}>&#43;</span>
                </div>
              </li>
              <div className='px-4'><hr className='' /></div>
              <li className='p-3 rounded-lg text-[13px] font-light flex items-center justify-between'>
                <div className='flex items-center'>
                  <FaGamepad className='text-2xl text-[tomato]' />
                  <span className='ml-2 text-black'>{langChange.game_pocket}</span>
                  <span className='ml-2 block text-slate-400 border-slate-400 w-[23px] h-[23px] border text-[13px] text-center leading-[23px] rounded-full cursor-pointer' onClick={() => setGamePocketShow(true)}>&#63;</span>
                </div>
                <div className='flex items-center'>
                  <span className='text-black'>{value?.game_info?.balance?.toLocaleString('en-us') ?? 0} {langChange.kyat}</span>
                  <span className='ml-2 block w-[23px] h-[23px] border border-[var(--main-color)] text-[13px] text-center leading-[23px] rounded-full cursor-pointer text-[var(--main-color)]' onClick={transferToGameShowHandler}>&#43;</span>
                </div>
              </li>
            </ul>
            <ul className='mt-3 flex items-center justify-center'>
              <li className='mr-2 cursor-pointer w-[120px] group' onClick={() => fetchPocketStatus('Deposit')}>
                <div className='shadow p-3 rounded-lg text-[13px] mb-3 bg-[var(--primary-color)] border-solid hover:text-[var(--text-color)] duration-300'>
                  <img src={depositImg} alt='deposit' className='h-[55px] mx-auto p-1' />
                  <p className='mb-0 text-center mt-2 text-black group-hover:text-[var(--text-color)]'>{langChange.deposit}</p>
                </div>
              </li>
              <li className='ml-2 cursor-pointer w-[120px] group' onClick={() => fetchPocketStatus('Withdraw')}>
                <div className='shadow p-3 rounded-lg text-[13px] mb-3 bg-[var(--primary-color)] hover:text-[var(--text-color)] duration-300'>
                  <img src={withdrawImg} alt='withdraw' className='h-[55px] mx-auto p-1' />
                  <p className='mb-0 text-center mt-2 text-black group-hover:text-[var(--text-color)]'>{langChange.withdraw}</p>
                </div>
              </li>
            </ul>
            <ul className=''>
              <li className='shadow text-black bg-[var(--primary-color)] w-full text-center p-3 rounded-lg cursor-pointer hover:bg-[var(--main-color)] hover:text-[var(--text-color)]' onClick={e => navigate('/deposit-transaction')}>{langChange.deposit_and_withdraw_condition}</li>
              {/* <li className='mt-7 flex items-center cursor-pointer text-black hover:text-[var(--text-color)]' onClick={() => { setVideoUrl('https://www.youtube.com/embed/D24Oo0B5AN8?si=BIjLBxEUSAynMUKU') }} >
                <FaRegPlayCircle className='text-xl me-2' />
                {langChange.how_to_deposit}
              </li>
              <li className='mt-4 mb-5 flex items-center cursor-pointer text-black hover:text-[var(--text-color)]' onClick={() => { setVideoUrl('https://www.youtube.com/embed/gHNIGgn3m-A?si=3ZpTMAiQGX6THaOz') }}>
                <FaRegPlayCircle className='text-xl me-2' />
                {langChange.how_to_withdraw}
              </li> */}
            </ul>
            <div className='shadow mt-3 bg-[var(--primary-color)] p-6 rounded-lg text-[13px] mb-2'>
              <h5 className='text-center text-[13px] mb-0 text-black'>{langChange.deposit_and_withdraw_time}</h5>
              <hr className='my-4' />
              <p className='mb-0 text-center text-black'>{langChange.service_time}</p>
            </div>
          </div>
        </div>
        <Nav />
      </div>
      <DetailModel isShow={pocketShow} setIsShow={setPocketShow}>
        <div className='text-[13px] mb-2'>
          <div className='mb-4 flex items-center justify-center text-[13px] font-bold'><FiPocket className='mr-2 text-xl text-[#eb5c00]' /> <span className='text-black'>{langChange.main_pocket}</span></div>
          <div className='mb-3 flex items-center justify-start text-sm'><p className='text-[12px] mr-2 w-[25px] h-[25px] text-center leading-[25px] rounded-full bg-[tomato]'>2D</p> <span className='text-black'>2D</span></div>
          <div className='mb-3 flex items-center justify-start text-sm'><p className='text-[12px] mr-2 w-[25px] h-[25px] text-center leading-[25px] rounded-full bg-[skyblue]'>3D</p> <span className='text-black'>3D</span></div>
          <div className='mb-3 flex items-center justify-start text-sm'><img src={withdrawImg} className='w-5 mr-2' /> <span className='text-black'>{langChange.withdraw}</span></div>
          <div className='mb-3 flex items-center justify-start text-sm'><img src={depositImg} className='w-5 mr-2' /> <span className='text-black'>{langChange.deposit}</span></div>
          <div className='mb-0 flex items-center justify-start text-sm'><FiRepeat className='text-lg text-[#eb5c00] mr-[10px]' /> <span className='text-black'>{langChange.transfer_to_game_pocket}</span></div>
        </div>
      </DetailModel>
      <DetailModel isShow={gamePocketShow} setIsShow={setGamePocketShow}>
        <div className='text-[13px] mb-2'>
          <div className='mb-4 flex items-center justify-center text-[13px] font-bold'><FaGamepad className='mr-2 text-xl text-[tomato]' /> <span className='text-black'>{langChange.game_pocket}</span></div>
          <div className='mb-3 flex items-center justify-start text-sm'><img src={footballImg} className='w-5 mr-2' /> <span className='text-black'>{langChange.football}</span></div>
          <div className='mb-3 flex items-center justify-start text-sm'><img src={slotImg} className='w-5 mr-2' /> <span className='text-black'>{langChange.slots_games}</span></div>
          <div className='mb-3 flex items-center justify-start text-sm'><img src={liveCasinoImg} className='w-5 mr-2' /> <span className='text-black'>{langChange.live_casino_and_other_games}</span></div>
          <div className='mb-0 flex items-center justify-start text-sm'><FiRepeat className='text-lg text-[#eb5c00] mr-[10px]' /> <span className='text-black'>{langChange.transfer_to_main_pocket}</span></div>
        </div>
      </DetailModel>
      <DetailModel isShow={transferToPocketShow} setIsShow={setTransferToPocketShow} loading={loading} event={() => submitToPocketTransfer()}>
        <div className='flex justify-center items-center py-3'>
          <FaGamepad className='mr-2 text-5xl text-[tomato]' />
          <div className='w-[75px]'>
            <Marquee direction='right'>
              <BiSolidRightArrow className='text-[#fbba49] text-lg' />
              <BiSolidRightArrow className='text-[#fbba49] text-lg' />
              <BiSolidRightArrow className='text-[#fbba49] text-lg' />
              <BiSolidRightArrow className='text-[#fbba49] text-lg' />
            </Marquee>
          </div>
          <FiPocket className='ml-2 text-5xl text-[#fbba49]' />
        </div>
        <div className='text-[13px] mb-2'>
          <input className='w-full bg-white p-3 border border-sky-600 rounded-md text-slate-900' placeholder={langChange.transfer_amount} type="text" value={toPocketAmount} onChange={toPocketAmountHandler} onKeyDown={e => e.key === 'Enter' && submitToPocketTransfer()} />
        </div>
        {errorPocketAmount && <span className='mt-2 block text-red-600'>{errorPocketAmount}</span>}
      </DetailModel>
      <DetailModel isShow={transferToGameShow} setIsShow={setTransferToGameShow} loading={loading} event={() => submitToGameTransfer()}>
        <div className='flex justify-center items-center py-3'>
          <FiPocket className='mr-2 text-5xl text-[#fbba49]' />
          <div className='w-[75px]'>
            <Marquee direction='right'>
              <BiSolidRightArrow className='text-[#fbba49] text-lg' />
              <BiSolidRightArrow className='text-[#fbba49] text-lg' />
              <BiSolidRightArrow className='text-[#fbba49] text-lg' />
              <BiSolidRightArrow className='text-[#fbba49] text-lg' />
            </Marquee>
          </div>
          <FaGamepad className='ml-2 text-5xl text-[tomato]' />
        </div>
        <div className='text-[13px] mb-2'>
          <input className='w-full bg-white p-3 border border-sky-600 rounded-md text-slate-900' placeholder={langChange.transfer_amount} type="text" value={toGameAmount} onChange={toGameAmountHandler} onKeyDown={e => e.key === 'Enter' && submitToGameTransfer()} />
        </div>
        {errorGameAmount && <span className='mt-2 block text-red-600'>{errorGameAmount}</span>}
      </DetailModel>
      <PopupFrame setUrl={setVideoUrl} url={videoUrl} />
    </>
  )
}

export default Pocket
