import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import { THREE_D_LUCKY_NUMS_API } from "../../services/constant";
import { langChange } from "../../lang";
import Loader from "../../utils/Loader";
import { useSelector } from "react-redux";
import Nav from "../../layout/Nav";

const WkThreedResultHistory = () => {
  const [loading, setLoading] = useState(false);
  const [resultHistory, setResultHistory] = useState([]);
  const navigate = useNavigate();

  const fetchResultHistory = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("b_m_a"));
    if (token) {
      const res = await getMethod(`${THREE_D_LUCKY_NUMS_API}?type=Weekend`, token.token);
      if (res) {
        setLoading(false);
        if (res.data) {
          setResultHistory(res.data);
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchResultHistory();
  }, []);

  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang])

  return (
    <div className='pb-[100px] relative min-h-screen'>
      <div className="mt-5">
        {
          resultHistory.length > 0 &&
          <div className="mb-5">
          <h2 className="text-center text-[var(--main-color)] text-[100px] font-bold">
              {resultHistory.sort((a, b) => b.id - a.id)[0].three_digit_number ?? '!!'}
          </h2>
          <p className="text-center text-[var(--main-color)]">
         {' '} Saturday {', '}
          {resultHistory.sort((a, b) => b.id - a.id)[0].lottery_date ?? '--:--:--   --:--:--'}
          </p>
      </div>
        }
        {resultHistory.length > 0 ? (
          resultHistory.sort((a, b) => b.id - a.id).map((result) => (
            <div key={result?.id} className="shadow text-black my-3 flex justify-between items-center px-5 md:px-10 h-[90px] bg-[var(--primary-color)] rounded-md">
              <div className="w-[75%]">
                <p className="text-center text-[23px] font-semibold tracking-widest text-[var(--main-color)]">
                  {result.three_digit_number ?? '!!'}
                </p>
                <p className="text-center text-[15px] border-t-[1px] pt-2">
                  {langChange.twit} - {(result?.twit).join(", ")}
                </p>
              </div>
              <div className="w-[25%]">
                <p className="text-end text-[15px]">
                  {result.lottery_date.split(",")[0]}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="text-xl h-24 flex items-end justify-center text-black">
            No Result Found!
          </div>
        )}
        {loading && <Loader />}
      </div>
      <Nav />
    </div>
  );
};

export default WkThreedResultHistory;
