// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Number_number__jb4qW {
  border: 1px solid #354ebded;
  padding: 10px 0;
  background-color: #354ebded;
  border-radius: 10px;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: block;
  transition: 0.3s;
  width: 50px;
  height: 50px;
  text-align: center;
}
.Number_closed_number__zPdBe {
  background-color: #fff;
  color: #354ebded;
  cursor: auto;
}
.Number_number-checked__J9Xoo {
  display: none;
}
.Number_number-checked__J9Xoo:checked ~ .Number_number__jb4qW {
  border-color: #f6e25d;
  background-color: #f6e25d;
  color: #1d1848;
}
.Number_percent-bar__-QhuC {
  height: 4px;
  background-color: #fff;
  display: inline-block;
  width: 90%;
  position: absolute;
  left: 2.5px;
  bottom: 3px;
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Number.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,eAAe;EACf,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,yBAAiB;UAAjB,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,WAAW;EACX,sBAAsB;EACtB,qBAAqB;EACrB,UAAU;EACV,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".number {\n  border: 1px solid #354ebded;\n  padding: 10px 0;\n  background-color: #354ebded;\n  border-radius: 10px;\n  position: relative;\n  user-select: none;\n  cursor: pointer;\n  display: block;\n  transition: 0.3s;\n  width: 50px;\n  height: 50px;\n  text-align: center;\n}\n.closed_number {\n  background-color: #fff;\n  color: #354ebded;\n  cursor: auto;\n}\n.number-checked {\n  display: none;\n}\n.number-checked:checked ~ .number {\n  border-color: #f6e25d;\n  background-color: #f6e25d;\n  color: #1d1848;\n}\n.percent-bar {\n  height: 4px;\n  background-color: #fff;\n  display: inline-block;\n  width: 90%;\n  position: absolute;\n  left: 2.5px;\n  bottom: 3px;\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"number": `Number_number__jb4qW`,
	"closed_number": `Number_closed_number__zPdBe`,
	"number-checked": `Number_number-checked__J9Xoo`,
	"percent-bar": `Number_percent-bar__-QhuC`
};
export default ___CSS_LOADER_EXPORT___;
