import React, { useEffect, useRef, useState } from "react";
import Header from "../layout/Header";
import { Link, useNavigate } from "react-router-dom";
import lang_image from "../assets/images/lang.png";
import file_image from "../assets/images/help.png";
import rule_image from "../assets/images/rules.png";
import reset_pass_image from "../assets/images/reset_password.png";
import contact_image from "../assets/images/contact_me.png";
import logout_image from "../assets/images/logout.png";
import { langChange } from "../lang";
import { useDispatch, useSelector } from "react-redux";
import DetailModel from "../utils/DetailModel";
import mm_flag from "../assets/images/icons/myanmar.png";
import eng_flag from "../assets/images/icons/english.png";
import profile_img from "../assets/images/vip-person.png";
import { langTypeHandler } from "../store/slices/langSlice";
import SingleUserInfo from "../utils/SingleUserInfo";
import Divider from "../utils/Divider";
import Title from "../utils/Title";
import Nav from "../layout/Nav";
import { FaCamera, FaRegArrowAltCircleRight } from "react-icons/fa";
import { bytesToSize, successToaster } from "../utils/Helper";
import { postMethod, postMethodFormData } from "../services";
import { CHANGE_NAME_API, CHANGE_PHOTO_API } from "../services/constant";
import { createPortal } from "react-dom";
import Loader from '../utils/Loader';
import { infoHandler } from "../store/slices/userInfoSlice";
import SweetAlert from 'sweetalert2';
import classes from '../assets/styles/Profile.module.css';

const Profile = () => {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLangChange, setIsLangChange] = useState(false);
  const { value } = useSelector((state) => state.userInfoSlice);
  const toastId = useRef(null);
  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  const auth = localStorage.getItem("b_m_a");
  const changeImgRef = useRef();

  const logoutHandler = () => {
    const token = localStorage.getItem("b_m_a");
    if (token) {
      localStorage.removeItem("b_m_a");
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const langHandler = (type) => {
    localStorage.setItem("b_m_lang", type);
    langChange.setLanguage(type);
    dispatch(langTypeHandler(type));
  };

  const saveEditHandler = async (value,setEdit,setEditVal) => {
    const token = JSON.parse(localStorage.getItem('b_m_a'));
    if(value){
        const res = await postMethod(
          CHANGE_NAME_API,
          { name: value },
          token.token
        );
        console.log(res)
        if(res){
          setLoading(false);
          if (res.con) {
            successToaster("Name change success",toastId);
            setEdit(false);
            setEditVal("");
            dispatch(infoHandler(res?.data?.user_info));
          };
        }
    }else{
      setEdit(false);
      setEditVal("");
    }
  };

  const clickPhotoHandler = async () => {
    changeImgRef.current.click();
  };

  const changePhotoHandler = async () => {
    const token = JSON.parse(localStorage.getItem('b_m_a'));
    let photoSize = bytesToSize(changeImgRef.current.files[0].size);
    

    if(photoSize.megabytes < 10){
        setLoading(true);
        let photoValue = changeImgRef.current.files[0];
        const formData = new FormData();
        formData.append('photo', photoValue);
        const res = await postMethodFormData(CHANGE_PHOTO_API, formData, token.token);
        if(res){
            setLoading(false);
            if(res.con){
                successToaster("Photo change success",toastId);
                setLoading(false);
                dispatch(infoHandler(res?.data?.user_info));
            };
        };
    }else{
      SweetAlert.fire({
        width: '330px',
        icon: 'error',
        title: 'Fail to upload!',
        text: 'Upload image size is maximun 10 Megabyte',
        confirmButtonText: 'Ok',
      });
    }

   
  };



  return (
    <>
      <div className="pb-[100px] relative min-h-screen">
        <Header />
        <div className="p-3">
          {
            !auth &&
            <Link to="/login">
              <button className="mb-4 flex items-center justify-center py-3 px-5 w-full hover:bg-[#ccc] bg-[#ddd] text-red-600 rounded-lg">
                  <FaRegArrowAltCircleRight className="text-red-600 mr-2" size={"25px"} /> {langChange.login_first}
              </button>
            </Link>
          }
          <div className="container mx-auto">
          {
            auth &&
            <div>
                <div className="relative">
                  <div className={`${classes.photoOverlay} relative w-[100px] h-[100px] mx-auto`} onClick={clickPhotoHandler}>
                    <img src={value?.user_info?.photo ?? profile_img} className="w-full h-full object-cover rounded-full mx-auto"/>
                    <div className="absolute top-0 w-[100px] h-[100px] flex items-center justify-center">
                      <FaCamera size="20px" color="white" />
                    </div>
                  </div>

                  <input className="hidden" type="file" ref={changeImgRef} onChange={changePhotoHandler}/>
                  {/* <p className="text-lg font-semibold absolute bottom-[45px] left-[50%] translate-x-[-50%] text-white">
                    {value?.user_info?.name ?? <Spinner />}
                  </p> */}
                </div>
                <Title name={langChange.profile_detail} />
                <Divider />
                <SingleUserInfo label={langChange.name} value={value?.user_info?.name} editAble={true} saveEditHandler={saveEditHandler}/>
                <SingleUserInfo label={langChange.phone} value={value?.user_info?.phone} />
                <SingleUserInfo label={langChange.user_code} value={value?.user_info?.id} />
                <SingleUserInfo label={langChange.main_pocket} value={`${value?.user_info?.current_balance?.toLocaleString('en-us')}`} extra_label={langChange.kyat} />
                <SingleUserInfo label={langChange.game_pocket} value={`${value?.game_info?.balance?.toLocaleString('en-us')}`} extra_label={langChange.kyat} />
                <Title name={langChange.payment_detail} custom={'mt-8'} />
                <Divider />
                <SingleUserInfo label={langChange.bank_type} value={value?.user_info?.name} />
                <SingleUserInfo label={langChange.bank_acc_name} value={value?.user_info?.name} />
                <SingleUserInfo label={langChange.bank_acc_no} value={value?.user_info?.phone} />
            </div>
          }
            
            <ul>
              <li className="cursor-pointer">
                <div className="p-2 py-1 border-b rounded-lg text-base flex items-center justify-between">
                  <div
                    className="flex items-center"
                    onClick={() => setIsLangChange(true)}
                  >
                    <img src={lang_image} className="w-[40px] me-[2px]" />
                    <span className="ml-2 text-black">
                      <span className="text-sm">{langChange.myanmar}</span> / Eng
                      / Zaygyi / Unicode
                    </span>
                  </div>
                </div>
              </li>
              {
                auth &&
                <li className="cursor-pointer">
                  <Link to="/recommendation-and-help">
                    <div className="p-2 py-1 border-b rounded-lg text-base flex items-center justify-between">
                      <div className="flex items-center">
                        <img src={file_image} className="w-[40px] me-[2px]" />
                        <span className="ml-2 text-black">
                          {langChange.recommendation_letter_and_help}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              }
              <li className="cursor-pointer">
                <Link to="/rules-and-regulations">
                  <div className="p-2 py-1 border-b rounded-lg text-base flex items-center justify-between">
                    <div className="flex items-center">
                      <img src={rule_image} className="w-[40px] me-[2px]" />
                      <span className="ml-2 text-black">
                        {langChange.rule_and_regulation}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              {
                auth &&
                <>
                  <li className="cursor-pointer">
                    <Link to="/change-password">
                      <div className="p-2 py-1 border-b rounded-lg text-base flex items-center justify-between">
                        <div className="flex items-center">
                          <img src={reset_pass_image} className="w-[40px] me-[2px]" />
                          <span className="ml-2 text-black">{langChange.change_password}</span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="cursor-pointer">
                    <Link to={"/contact"}>
                      <div className="p-2 py-1 border-b rounded-lg text-base flex items-center justify-between">
                        <div className="flex items-center">
                          <img src={contact_image} className="w-[40px] me-[2px]" />
                          <span className="ml-2 text-black">{langChange.contact}</span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li onClick={logoutHandler} className="cursor-pointer">
                    <div className="p-2 py-1 border-b rounded-lg text-base flex items-center justify-between">
                      <div className="flex items-center">
                        <img src={logout_image} className="w-[40px] me-[2px]" />
                        <span className="ml-2 text-black">{langChange.logout}</span>
                      </div>
                    </div>
                  </li>
                </>
              }
            </ul>
          </div>
        </div>
        <Nav />
        <DetailModel isShow={isLangChange} setIsShow={setIsLangChange}>
          <ul>
            <li
              className="p-2 flex items-center cursor-pointer"
              onClick={() => langHandler("unicode")}
            >
              <img
                className={`w-[30px] mr-1 rounded-full ${
                  lang === "unicode" ? "border-2 border-white" : ""
                }`}
                src={mm_flag}
                alt="mm_flag"
              />
              <span className="text-slate-600">{langChange.myanmar} (Unicode)</span>
            </li>
            <li
              className="p-2 flex items-center cursor-pointer"
              onClick={() => langHandler("zawgyi")}
            >
              <img
                className={`w-[30px] mr-1 rounded-full ${
                  lang === "zawgyi" ? "border-2 border-white" : ""
                }`}
                src={mm_flag}
                alt="mm_flag"
              />
              <span className="text-slate-600">{langChange.myanmar} (Zaygyi)</span>
            </li>
            <li
              className="p-2 flex items-center cursor-pointer"
              onClick={() => langHandler("english")}
            >
              <img
                className={`w-[30px] p-[2px] mr-1 rounded-full ${
                  lang === "english" ? "border-2 border-white" : ""
                }`}
                src={eng_flag}
                alt="mm_flag"
              />
              <span className="text-slate-600">English</span>
            </li>
          </ul>
        </DetailModel>
      </div>
      {
        createPortal(loading && <Loader />, document.getElementById("root"))
      }
    </>
  );
};

export default Profile;
