import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import classes from '../assets/styles/TwodLive.module.css';
import Loader from '../utils/Loader';

const TwodLive = () => {
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState({});
    const [animate12,setAnimate12] = useState(false);
    const [animate16,setAnimate16] = useState(false);
    const [hourTime,setHourTime] = useState("--");
    const [minuteTime,setMinuteTime] = useState("--");
    const [secondTime,setSecondTime] = useState("--");
    const [result,setResult] = useState("!!");

    const [set12,setSet12] = useState('- - - - -');
    const [set16,setSet16] = useState('- - - - -');
    const [value12,setValue12] = useState('- - - - -');
    const [value16,setValue16] = useState('- - - - -');
    const [result12,setResult12] = useState("--");
    const [result16,setResult16] = useState("--");
    const mounted = useRef(false);

    let currentDate = moment().tz("Asia/Yangon").format("DD-MMMM-YYYY");

    useEffect(()=>{
        !mounted.current && live(); 
        mounted.current = true;
        const timeAction = setInterval(updateTime , 3000);
        const liveAction = setInterval(live , 3000);
        return () => {
            mounted.current = false;
            clearInterval(timeAction);
            clearInterval(liveAction);
        };
    },[]);

    const live = async () => {
        try{
                const fetchNumber = await fetch('https://luke.2dboss.com/api/luke/twod-result-live',{
                method: 'GET',
                Accept: 'application/json',
                "Content-Type": "application/json",
            });
            const res = await fetchNumber.json();
            if(res){
                setLoading(false);
                if(res.length === 0){
                    return;
                }else{
                    if(res.message === "success"){
                        setData(res.data);
                    };
                };
            };
        }
        catch(err){
            console.log(err.message)
        }
    };

    const updateTime = () => {
        let currentTime = moment().tz("Asia/Yangon");
        let hour = (currentTime.hours()<10?'0':'') + currentTime.hours();
        let minute = (currentTime.minutes()<10?'0':'') + currentTime.minutes();
        let second = (currentTime.second()<10?'0':'') + currentTime.second();
        let timeconvert = moment().tz("Asia/Yangon").format("HH:mm:ss");
        setHourTime(hour);
        setMinuteTime(minute);
        setSecondTime(second);
        let twelve = "08:00:00";
        let endTwelve = "12:00:58";
        let four = "12:06:00";
        let endFour = "16:29:58";
        if (timeconvert > twelve && timeconvert < endTwelve){
            setAnimate12(true)
        }else{
            setAnimate12(false);
        };

        if (timeconvert > four && timeconvert < endFour){
            setAnimate16(true);
        }else{
            setAnimate16(false);
        };
    };

    useEffect(()=>{
        let twelve = "08:00:00";
        let endTwelve = "12:00:58";
        let four = "12:06:00";
        let endFour = "16:29:58";

        let timeconvert = moment().tz("Asia/Yangon").format("HH:mm:ss");
        if (timeconvert > "12:00:59" && timeconvert < "12:06:00"){
            setResult(data?.result_1200);
        }else{
            data?.result_430 === "--"? setResult(data?.live) : setResult(data?.result_430);
        };

        if (timeconvert > twelve && timeconvert < endTwelve){
            data?.set_1200 === "--" ? setSet12(data?.live_set): setSet12(data?.set_1200);
            data?.val_1200 === "--" ? setValue12(data?.live_val): setValue12(data?.val_1200);
        }else{
            setSet12(data?.set_1200);
            setValue12(data?.val_1200);
        };
  
        if (timeconvert > four && timeconvert < endFour){
            data?.set_430 === "--" ? setSet16(data?.live_set): setSet16(data?.set_430);
            data?.val_430 === "--" ? setValue16(data?.live_val): setValue16(data?.val_430);
        }else{
            setSet16(data?.set_430);
            setValue16(data?.val_430);
        };
  
        if (timeconvert > "12:00:59" && timeconvert < "12:06:00"){
            setSet12(data?.set_1200);
            setValue12(data?.val_1200);
        };
    
        if(timeconvert > "12:01:00" && timeconvert < "12:05:00"){
            if(data?.result_1200 === "--"){
            setResult12(data?.live);
            }else{
            setResult12(data?.result_1200);
            }
        }else{
            setResult12(data?.result_1200 ?? "--")
        };
  
        if(timeconvert > "16:30:00" && timeconvert < "16:35:00"){
            if(data?.result_430 === "--"){
                setResult16(data?.live);
            }else{
                setResult16(data?.result_430);
            }
        }else{
            setResult16(data?.result_430 ?? "--");
        };
    },[data]);
    return (
        <>
            <div className='mb-10'>
                <div>
                    <h1 className={`${classes['live-number']} ${(animate12 || animate16) && classes.animation}`}>{result?? '!!'}</h1>
                    <div className={classes['updated-contaier']}>
                        <div>Updated: {currentDate} {hourTime}:{minuteTime}:{secondTime}</div>
                    </div> 
                </div>
                <div className='mt-6 bg-[var(--primary-color)] p-6 rounded-lg text-base mb-2 text-black'>
                    <h5 className='text-center text-lg mb-0'>12:01 PM</h5>
                    <hr className='my-3'/>
                    <div className={`mb-0 text-center ${classes.result}`}>
                        <div style={{width:'33%'}}>Set <p className={`${animate12 && classes.animation}`}>{set12??'- - - - -'}</p></div>
                        <div style={{width:'33%'}}>Value <p className={`${animate12 && classes.animation}`}>{value12??'- - - - -'}</p></div>
                        <div style={{width:'33%'}}>2D <div className={classes['result-number']}>{result12}</div></div>
                    </div>
                </div>
                <div className='mt-6 bg-[var(--primary-color)] p-6 rounded-lg text-base mb-2 text-black'>
                    <h5 className='text-center text-lg mb-0'>04:30 PM</h5>
                    <hr className='my-3'/>
                    <div className={`mb-0 text-center ${classes.result}`}>
                        <div style={{width:'33%'}}>Set <p className={`${animate16 && classes.animation}`}>{set16??'- - - - -'}</p></div>
                        <div style={{width:'33%'}}>Value <p className={`${animate16 && classes.animation}`}>{value16??'- - - - -'}</p></div>
                        <div style={{width:'33%'}}>2D <div className={classes['result-number']}>{result16}</div></div>
                    </div>
                </div>
                <hr className='my-3' style={{borderColor: 'var(--text-color)', opacity: 1}} />
                <div className='bg-[var(--primary-color)] p-6 rounded-lg text-base mb-2'>
                    <div className={`mb-0 text-center items-center ${classes.result}`}>
                        <div style={{width:'33%'}}><h5 className='text-lg'>09:30 AM</h5></div>
                        <div style={{width:'33%'}}>Modern <div className={classes['result-number']}>{data?.modern_930 ?? "--"}</div></div>
                        <div style={{width:'33%'}}>Internet <div className={classes['result-number']}>{data?.internet_930 ?? "--"}</div></div>
                    </div>
                </div>
                <div className='mt-3 bg-[var(--primary-color)] p-6 rounded-lg text-base mb-2'>
                    <div className={`mb-0 text-center items-center ${classes.result}`}>
                        <div style={{width:'33%'}}><h5 className='text-lg'>02:00 PM</h5></div>
                        <div style={{width:'33%'}}>Modern <div className={classes['result-number']}>{data?.modern_200 ?? "--"}</div></div>
                        <div style={{width:'33%'}}>Internet <div className={classes['result-number']}>{data?.internet_200 ?? "--"}</div></div>
                    </div>
                </div>
                {/* <button className={`py-3 px-7 bg-[#f6e25d] rounded-lg text-[#1d1848] ${classes.bets} bottom-[100px] lg:bottom-[65px]`}>ထိုးမည်</button> */}
            </div>
            {
                ReactDOM.createPortal(loading && <Loader />, document.getElementById("portal"))
            }
        </>
    )
}

export default TwodLive
