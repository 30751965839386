import React, { useEffect, useState } from 'react'
import Layout from '../utils/Layout'
import { FiFacebook, FiMail, FiPhone } from 'react-icons/fi'
import { getMethod } from '../services';
import { CONTACT_API } from '../services/constant';
import { FaTelegram, FaViber } from 'react-icons/fa';
import { BiLogoTelegram } from 'react-icons/bi';
import ContactCard from './ContactCard';
const Contact = () => {
    const [contact,setContact] = useState([]);
    const fetchContact = async () => {
        const res = await getMethod(`${CONTACT_API}`);
        if(res.con){
            setContact(res.data);
        }
    };
    useEffect(()=>{
        fetchContact();
    },[]);
   
  return (
        <Layout>
            <div className='flex flex-wrap gap-3 justify-center mb-10'>
                <p className='text-black mb-0 text-center'>
                    အောက်ပါဖုန်းနံပါတ်သည် BM 2D ကုမ္ပဏီ၏<br/>
                     ဝန်ဆောင်မှုဖုန်းနံပါတ်ဖြစ်သည်။
                </p>
                <p className='text-black mb-2 text-center'>
                    နေ့စဉ်(၂၄) နာရီ ၊ ပိတ်ရက်မရှိ ဝန်ဆောင်ပေးနေပါသည်။
                </p>
                <ContactCard title={'Facebook'} iconColor="bg-[#1877F2]" link={contact?.facebook} redLink={contact?.facebook} icon={<FiFacebook className='text-2xl' />} />
                <ContactCard title={'Viber'} iconColor="bg-[#8f5db7]" link={contact?.viber} redLink={`viber://chat/?number=${contact?.viber}`} icon={<FaViber className='text-2xl' />} />
                <ContactCard title={'Phone'} iconColor="bg-[green]" link={contact?.phone} redLink={`tel:${contact?.phone}`} icon={<FiPhone className='text-2xl' />} />
                <ContactCard title={'Telegram'} iconColor="bg-[#0088cc]" link={contact?.telegram_channel} redLink={contact?.telegram_channel} icon={<BiLogoTelegram className='text-2xl' />} />
            </div>
        </Layout>
  )
}

export default Contact
