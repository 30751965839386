import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { useSelector } from 'react-redux'
import noteImg from '../assets/images/icons/history.png';
import prizeImg from '../assets/images/icons/winner.png';
import numberImg from '../assets/images/icons/number-2.png';
import { Link, useNavigate } from 'react-router-dom'
import { getMethod } from '../services'
import { TWO_D_REAL_TIME_API, BURMA_TWO_D_STATUS_API } from '../services/constant'
import moment from 'moment-timezone'
import { langChange } from '../lang'
import Nav from '../layout/Nav';
import {IoIosArrowRoundBack} from 'react-icons/io';
import TimeModel from '../utils/TimeModel';
import { FiClock } from 'react-icons/fi';

const BurmaTwod = () => {
  const [timeShow,setTimeShow] = useState(false);
  const navigate = useNavigate();
  const [towDStatus, setTowDStatus] = useState(false);
  const [currentTime, setCurrentTime] = useState('');
  const [morningOpenTime, setMorninigOpenTime] = useState('');
  const [morningCloseTime, setMorningCloseTime] = useState('');
  const [eveningOpenTime, setEveningOpenTime] = useState('');
  const [eveningCloseTime, setEveningCloseTime] = useState('');

  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang])

  const token = JSON.parse(localStorage.getItem('b_m_a'));
  const fetchtowDStatus = async () => {
    if (token) {
      const res = await getMethod(BURMA_TWO_D_STATUS_API, token.token);
      res.status === 200 && setTowDStatus(true);
    }
  }

  useEffect(()=>{
    fetchtowDStatus();
  }, [])

  const fetchTowDRealTime = async () => {
    if (token) {
      const res = await getMethod(`${TWO_D_REAL_TIME_API}?type=Burma`, token.token)
      if (res.con) {
        if (res.data) {
          res.data.map(row => {
            if (row.lottery_time === 'One') {
              setMorninigOpenTime(row.opening_time);
              setMorningCloseTime(row.closing_time);  
            }
            if (row.lottery_time === 'Two') {
              setEveningOpenTime(row.opening_time);
              setEveningCloseTime(row.closing_time);
            }
          })
        }
      }
    }
  };
  console.log(morningOpenTime)
  console.log(morningCloseTime)

  const towDStatusHandler = () => {
    fetchTowDRealTime();
    setCurrentTime((moment().tz("Asia/Yangon").format('HH:mm:ss')));
    if (towDStatus) {
      setTimeShow(true);
    } else {
      navigate('/closed')
    }    
  };

  const backHandler = () => navigate(-1);
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className='px-3 mt-3'>
            <button className='bg-[var(--main-color)] p-1 rounded-full' onClick={backHandler}>
              <IoIosArrowRoundBack size={'30px'}/>
            </button>
        </div>
        <div className='h-[100vh] overflow-auto'>
              <div className='container mx-auto'>
                  <div className='p-3'>
                    <ul>
                        <li className='shadow bg-[var(--primary-color)] p-3 rounded-lg text-[13px] flex items-center justify-between'>
                            <div className='w-1/3 flex items-center justify-center flex-col cursor-pointer' onClick={()=> navigate('history')}>
                                <img src={noteImg} className='w-[25px] mr-2' />
                                <p className='text-[13px] mb-0 mt-1 text-black'>{langChange.history}</p>
                            </div>
                            <div className='w-1/3 flex items-center justify-center flex-col cursor-pointer' onClick={()=> navigate('winner-lists')}>
                                <img src={prizeImg} className='w-[25px]' />
                                <p className='text-[13px] mb-0 mt-1 text-black'>{langChange.winner}</p>
                            </div>
                            <div className='w-1/3 flex items-center justify-center flex-col cursor-pointer' onClick={towDStatusHandler}>
                                <img src={numberImg} className='w-[25px]' />
                                <p className='text-[13px] mb-0 mt-1 text-black'>{langChange.bet}</p>
                            </div>
                        </li>
                    </ul>
                    <div className='mt-4' style={{height: '100vh'}}>
                       <iframe src="https://live.burma2d.net/" width="100%" height="100%" title="description"></iframe>
                    </div>
                  </div>
              </div>
        </div>
        <Nav />
        <TimeModel isShow={timeShow} setIsShow={setTimeShow}>
            <h3 className='mb-5 flex items-center justify-center text-[13px] font-bold'><FiClock className='mr-2 text-xl text-[#eb5c00]' /> <span className='text-black'>{langChange.select_lottery_time}</span></h3>
            <ul>
              {
                (currentTime >= morningOpenTime && currentTime <= morningCloseTime) &&
                <li>
                  <Link to="time/12:01">
                      <div className='text-center text-[13px] block border-[#3A55C8] border p-3 mb-4 rounded-[50px] text-black cursor-pointer'>
                        12:00 PM
                      </div>
                  </Link>
                </li>
              }
              {
                (currentTime >= eveningOpenTime && currentTime <= eveningCloseTime) &&
                <li>
                  <Link to="time/04:30">
                      <div className='text-center text-[13px] block border-[#3A55C8] border p-3 mb-4 rounded-[50px] text-black cursor-pointer'>
                        04:30 PM
                      </div>
                  </Link>
                </li>
              }
            </ul>
        </TimeModel>
      </div>
    </>
  )
}

export default BurmaTwod;
