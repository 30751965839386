import React from 'react'
import Header from '../layout/Header'
import Banner from './Banner'
import ProviderList from './ProviderList'
import Nav from '../layout/Nav'

const Main = () => {
  return (
   <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <Banner />
        <ProviderList />
        <Nav />
      </div>
    </>
  )
}

export default Main
