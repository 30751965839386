import React from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

export default function Back({home = false}) {
    const navigate = useNavigate();
  return (
    <div className='absolute top-[85px] md:top-[120px] left-[10px] md:left-10'>
        <FiArrowLeft className='text-4xl cursor-pointer p-1 bg-[var(--accent-color)] text-blue-900 rounded-full hover:bg-yellow-500' onClick={e => navigate(home ? '/' : -1)} />
    </div>
  )
}
