import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "../../assets/styles/WinnerList.module.css";
import NoData from "../../utils/NoData";
import Loader from "../../utils/Loader";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import { THREE_D_WINNER_API } from "../../services/constant";
import Pagination from "../../utils/Pagination";
import Header from "../../layout/Header";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import Nav from "../../layout/Nav";
import TopLeaderboard from "../../utils/TopLeaderboard";
import { IoIosArrowRoundBack } from "react-icons/io";

const WkThreedWinnerList = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [winnerLists, setWinnerLists] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);

  const fetchWinnerList = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("b_m_a"));
    if (token) {
      const res = await getMethod(`${THREE_D_WINNER_API}?type=Weekend`, token.token);
      if (res) {
        setLoading(false);
        if (res.data) {
          setWinnerLists(res.data);
        }
        if (res.meta) {
          if (res.meta.from) {
            setFrom(res.meta.from);
          }
          if (res.meta.last_page) {
            setTotalPage(res.meta.last_page);
          }
        }
      }
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    fetchWinnerList();
  }, [page]);

  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang]);
  const backHandler = () => navigate(-1);
  return (
    <>
      <div className='pb-[100px] relative min-h-screen'>
        <Header />
        <div className='px-4 mt-2'>
            <button className='bg-[var(--main-color)] p-1 rounded-full' onClick={backHandler}>
            <IoIosArrowRoundBack size={'30px'}/>
            </button>
        </div>
        <TopLeaderboard data={winnerLists}/>
        <div className="container mx-auto min-h-[70vh] my-5">
          <div className="p-4 mx-auto">
            <div className="flex items-center justify-center flex-col cursor-pointer">
              {/* <img src={prizeImg} className="w-[50px]" /> */}
              <p className="text-[16px] mb-0 mt-3 text-black">ထီပေါက်သူများ</p>
            </div>
            <div
              className={`shadow ${classes["auth-card-body"]} bg-[var(--primary-color)] rounded-lg mt-2`}
            >
              <div className={classes["table-card-container"]}>
                <ul className={classes["table-header"]}>
                  <li className="text-center">
                    <b>{langChange.sr}</b>
                  </li>
                  <li className="text-center">{langChange.lucky_number}</li>
                  <li className="text-center">{langChange.twit}</li>
                  <li className="text-center">{langChange.bet_amount}</li>
                  <li className="text-center">{langChange.total}</li>
                  <li className="text-center">{langChange.status}</li>
                  <li className="text-center">{langChange.date}</li>
                </ul>
                {winnerLists.length > 0 ? (
                  winnerLists.map((winner, i) => (
                    <ul key={i} className={classes["table-header"]}>
                      <li className="text-center">{i + from}.</li>
                      <li className="text-center text-[var(--main-color)]">
                        {winner.lucky_number ?? "--"}
                      </li>
                      <li className="text-center">{winner.votes ?? "--"}</li>
                      <li className="text-end">
                        {winner.amount.toLocaleString("en-us")}
                      </li>
                      <li className="text-end">
                        {winner.total.toLocaleString("en-us")}
                      </li>
                      <li className="text-center">
                        {winner.status === "Full" ? langChange.full : langChange.twit}
                      </li>
                      <li className="text-center">{winner.date}</li>
                    </ul>
                  ))
                ) : (
                  <NoData />
                )}
              </div>
              {totalPage > 1 && (
                <div className="flex justify-center pt-2">
                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </div>
              )}
            </div>
            {ReactDOM.createPortal(
              loading && <Loader />,
              document.getElementById("portal")
            )}
          </div>
        </div>
        <Nav />
      </div>
    </>
  );
};

export default WkThreedWinnerList;
